import React from 'react'
import './Culture.css'

// Assets import
import star from '../../../../assets/Star.webp';
import story from '../../../../assets/culture.webp';

function Culture() {
    return (
        <>
            <div className="container culture-wrapper my-5">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 culture-content">
                        <div className="star-wrapper" style={{ justifyContent: "start", display: "flex", flexDirection: "row", gap: "5px" }}>
                            <img className="star" src={star} alt="Star" />
                            <p style={{fontSize: "1.2rem", fontWeight: "bold"}}>Our Culture</p>
                        </div>
                        <h1>Turning Your Startup Dreams into Reality</h1>
                        <p className="culture-text">
                            We bridge the gap between entrepreneurial vision and technical execution. We're a full-stack software development company specializing in building impactful, enterprise-grade solutions for diverse industries. We support startups by providing them with the technical expertise and resources they need to succeed.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-3 culture-banner">
                        <img src={story} alt="9exGen" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Culture