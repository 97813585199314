import React, { useState } from "react";
import "./Career.css";

// Assetsimport
import life1 from "../../../assets/life1.webp";
import life2 from "../../../assets/life2.webp";
import life3 from "../../../assets/life3.webp";

function Life() {
  return (
    <>
      <div className="container-fluid col-lg-12 col-md-12 col-sm-12 col-12 life-wrapper">
        <div
          className="star-wrapper"
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <img className="star" src={star} alt="Star" /> */}
          <p>Life at 9exGen Solutions</p>
          <h3 className="section-head">
            Working at 9exGen Solutions is more than just a job. It's an
            opportunity to be part of a community of innovators and
            problem-solvers who are dedicated to making a difference. Our modern
            office space is designed to inspire creativity and collaboration,
            and our regular team events, hackathons, and social gatherings
            ensure that we have fun while working hard.
          </h3>
          <div className="row col-lg-12 col-md-12 col-sm-12 col-12 life-img">
            <img src={life1} alt="life1" />
            <img src={life2} alt="life2" />
            <img src={life3} alt="life3" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Life;
