import React from 'react'
import './Vision.css'

function VisionCard({Icon, heading, detail}) {
  return (
     <>
       <div className='vision-card'>
         <div className='icons'>{Icon}</div>
         <h3>{heading}</h3>
         <p>{detail}</p>
       </div>
     </>
  )
}

export default VisionCard