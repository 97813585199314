import React from "react";
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";
import { useLocation } from "react-router-dom";

const descriptions = [
  "A sophisticated platform designed to enhance customer engagement through a customizable loyalty program. Superloyal offers businesses the ability to create personalized rewards and tailored promotional offers, fostering stronger connections with their customers. By leveraging data and personalization, Superloyal helps businesses boost customer retention and satisfaction with innovative loyalty solutions.",
];

function SuperLoyalProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Super Loyal"} />
      <Introduction
        src={project.imagePath}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default SuperLoyalProjectDetail;
