import React from "react";
import "./Career.css";
import { PopupButton } from "@typeform/embed-react";

// Assets import
import star from "../../../assets/Star.webp";
import home from "../../../assets/career1.webp";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import CardDetail from "./CardDetail";
import Process from "./Process";
import Life from "./Life";
import { useNavigate } from "react-router-dom";

function Career() {
  const navigate  = useNavigate()

    const caseStudies = [
        {
          title: "Innovation-Driven Culture",
          detail:
            "We believe in pushing the boundaries of technology. Our team is constantly exploring new ideas, experimenting with cutting-edge tools, and staying ahead of industry trends.",
          
        },
        {
          title: "Impactful Projects",
          detail:
            "Work on exciting projects with some of the most innovative startups. Your contributions will directly impact their success and help shape the future of technology.",
         
         
        },
        {
          title: "Collaborative Environment",
          detail:
            "We cultivate a team-oriented atmosphere where everyone's voice is heard. Collaboration and open communication are at the heart of our success.",
          
          
        },
        {
          title: "Growth Opportunities",
          detail: "We are committed to your professional development. From continuous learning programs to career advancement opportunities, we support your growth at every stage.",
        },
        {
          title:"Flexible Work Arrangements",
          detail:"We understand the importance of work-life balance. Enjoy flexible working hours, remote work options, and a supportive environment that respects your personal needs."
        },
        {
          title:"Inclusive Culture",
          detail:"Diversity and inclusion are fundamental to our values. We celebrate differences and believe that a diverse team leads to better solutions and a richer work experience."
        },
      ];
  return (
    <>
      <Navbar />
      <div className="container career-wrapper my-5">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 header-carrer">
            <h1>Innovate, Create, and Grow with Us</h1>
            <p className="header-text">
            We are passionate about technology and innovation. As a leading software house specializing in supporting startups, we thrive on creating groundbreaking solutions that transform ideas into successful businesses. Our collaborative and dynamic environment fosters creativity, growth, and excellence.
            </p>
            <div className="buttons-wrap">
              {/* <button type="submit" className="btn btn-lg card-btn">
              Software Development Roles
              </button> */}
              <button onClick={()=> navigate('/career/job-positions')} type="submit" className="btn btn-lg card-btn">
              All Opportunities
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-3 header-banner">
            <img src={home} alt="9exGen" />
          </div>
        </div>
        <div className="row col-12 my-5 top-head">
            <div className="top-header">
          <h3 className="heading">Why Work With Us?</h3>
          </div>
        </div>
        <div
          className="row gap-5 mb-5"
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
          }}
        >
          {caseStudies.map((study, index) => (
            <CardDetail key={index} data={study} />
          ))}
        </div>
      </div>
      <Process/>
      <Life/>
      <Footer />
    </>
  );
}

export default Career;
