import React from 'react'
import './Pricings.css'

// Components import
import PricingCard from './PricingCard';

// Assets import
import star from '../../../../assets/Star.webp';

const itemsArray = [
    ['UI-UX Designing', 'Website Developers'],
    ['Managers', 'Frontend Engineers', 'Backend Engineers'],
    ['Digital Marketing', 'Content Writing'],
    ['SEO Experts', 'Quality Assurance', 'Architects'],
    ['Managers', 'Frontend Engineers'],
    ['Architects'],
];

function Pricings() {

    return (
        <>
            <div className='container pricing-wrapper'>
                <div className="row">
                    <div className="col-12 mb-5">
                        <div className="star-wrapper" style={{ justifyContent: "center", paddingTop: "50px", alignItems: "center" }}>
                            <img className="star" src={star} alt="Star" />
                            <p style={{fontSize: "1.6rem", fontWeight: "bold" }}>Pricings</p>
                        </div>
                        <h3 className='section-heading'>Partner with us</h3>
                        <p className='section-text'>Elevate Your Digital Presence: Partner with Us for a Transformative Experience</p>
                    </div>
                </div>
                <div className='container'>
                    <div className="row justify-content-center align-items-center">
                        <PricingCard />
                        <div className="col-lg-9 col-md-12 pricing-detail-box">
                            <div className='buttons-wrapper'>
                                <button type="submit" className="btn btn-lg card-btn">Dedicated Team</button>
                                <button type="submit" className="btn btn-lg card-btn">Staff Augmentation</button>
                            </div>
                            <div className="items">
                                {itemsArray.map((row, rowIndex) => (
                                    <div key={rowIndex} className="items-row">
                                        {row.map((item, itemIndex) => (
                                            <p key={itemIndex}>{item}</p>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pricings