import React from "react";
import "../CaseStudyPage.css";

import { PopupButton } from "@typeform/embed-react";

// Assets import
import self from "../../../../assets/self.webp";
import story from "../../../../assets/solution.webp";

function Solution({solution}) {
  return (
    <>
      <div className="container intro-wrapper my-5">
        <div className="row intro-head">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-3 intro-banner">
            <img src={story} alt="9exGen" />
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12 intro-content">
            <h1>The Solution</h1>
            {solution.map((desc, index) => (
              <p className="intro-text" key={index}>
                {desc}
              </p>
            ))}
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 my-3 center-img">
          <img src={self} alt="9exGen" />
        </div>
      </div>
    </>
  );
}

export default Solution;
