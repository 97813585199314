import React from 'react'
import './Story.css'

import { PopupButton } from '@typeform/embed-react'

// Assets import
import star from '../../../../assets/Star.webp';
import story from '../../../../assets/story.webp';

function Story() {
  return (
    <>
        <div className="container story-wrapper my-5">
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 story-content">
                    <div className="star-wrapper" style={{justifyContent: "start"}}>
                        <img className="star" src={star} alt="Star" />
                        <p>Story</p>
                    </div>
                    <h1>Code Your Dreams Into Reality: Where Startups, Ventures and Technology Meet</h1>
                    <p className="story-text">
                    We believe that every startup dream deserves to be realized. That's why we have cultivated a culture of innovation, where creativity and technology converge to bring ideas to life. Our multidisciplinary team of experts is dedicated to transforming your vision into a digital masterpiece. Through a process of iterative refinement and strategic implementation, we empower you to navigate the complexities of the digital landscape with confidence. Together, let's write the next chapter of your success story.
                    </p>
                    <div className="trust-btn-wrapper">
                        <PopupButton
                            id="hjTccK4p"
                            className="btn CTA"
                            hidden={{ foo: 'foo value', bar: 'bar value' }}
                        > Get Started </PopupButton>
                    </div>
                    
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-3 story-banner">
                    <img src={story} alt="9exGen" />
                </div>
            </div>
        </div>
    </>
  )
}

export default Story