import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Blogs.css";

// Components Import
import BlogBox from "./BlogBox";

// Assets Import
import star from "../../../assets/Star.webp";

function BlogsContainer() {
  const token = "0fa2b7cb9d9aa5bff41508a1d1dfb6ff";

  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([]);

  const fetchBlogs = () => {
    axios
      .get("https://cca.9exgen.com/api/9ex_blogs", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setBlogs(response.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <div className="container-fluid blogs-container">
        <div className="row">
          <div className="col-12 mb-5">
            <div className="star-wrapper">
              <img className="star" src={star} alt="Star" />
              <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>Insights</p>
            </div>
            <h3 className="section-heading">
            <span style={{ fontSize: "4rem" }}>
              Beyond the Code:{" "}
              </span>
                Discover the Insights Shaping Us.
            </h3>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {blogs.map((data, index) => (
              <BlogBox key={index} data={data} />
            ))}
          </div>
        </div>
        <div className="row py-5">
          <button
            type="submit"
            className="btn btn-lg"
            style={{ backgroundColor: "#04153A", color: "white" }}
            onClick={() => navigate("/blogs")}
          >
            See All Insights
            <i
              className="fa-solid fa-arrow-right"
              style={{
                fontSize: "1.6rem",
                color: "white",
                paddingLeft: "10px",
              }}
            ></i>{" "}
          </button>
        </div>
      </div>
    </>
  );
}

export default BlogsContainer;
