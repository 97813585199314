import React from "react";
import { useLocation } from "react-router-dom";
// import components
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";

const descriptions = [
  "A transformative digital platform for SMEs, offering a comprehensive suite of tools including automated inventory management, online POS systems, and data analytics. Dukandar Online helps small and medium-sized businesses drive growth and efficiency with cutting-edge technology. The platform supports businesses in optimizing their operations and enhancing their digital capabilities.",
];

function DukandarOnlineProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Dukandar Online"} />
      <Introduction
        src={project.imagePath}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default DukandarOnlineProjectDetail;
