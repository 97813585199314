import React from 'react'
import './Footprint.css'

function Footprint() {
    return (
        <>
            <div className='container-fuild footprint-wrapper'>
                <div className='contact-wraper'>
                    <i className="fa-solid fa-earth-americas"></i>
                    <h6>GLOBAL CLIENTELE</h6>
                </div>
                <h2>Our Presence Across Borders</h2>
                <p>Join Forces with Us Today for a Service Experience That Leaves a Lasting Impression!</p>
            </div>
        </>
    )
}

export default Footprint