import React from "react";
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";
import { useLocation } from "react-router-dom";

const descriptions = [
  "The pioneering Algerian platform for finding top-tier financing solutions, TAMWWIL simplifies the process with a secure and efficient approach for both individuals and businesses. The platform offers streamlined access to various financing options, helping users navigate their financial needs with ease. TAMWWIL is designed to support financial growth and stability through user-friendly and reliable services.",
];
const linkText =
  "Discover how TAMWWIL transforms financial solutions with efficiency and reliability";

function TamwwilProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Tamwwil"} />
      <Introduction
        src={project.imagePath}
        linkText={linkText}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default TamwwilProjectDetail;
