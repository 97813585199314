import React, { useState } from 'react'

function PortfolioCard({ imagePath, flipTitle, flipDetail,onClick }) {

    const [isFlipped, setIsFlipped] = useState(false);

    const handleMouseEnter = () => {
        setIsFlipped(true);
    };

    const handleMouseLeave = () => {
        setIsFlipped(false);
    };

  return (
    <>
        <div className={`portfolio-card ${isFlipped ? 'flipped' : ''}`}
            style={{backgroundImage: `url(${imagePath})`}}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            { isFlipped ?
                <div className="portfolioCard-overlay" >
                    <h3 style={{fontSize: "2.0rem", fontWeight: "bold"}}>{flipTitle}</h3>
                    <p onClick={onClick} style={{fontSize: "1.5rem", fontWeight: "500", textDecoration: "underline"}}>{flipDetail}</p>
                </div>
                : null
            }
        </div>
    </>
  )
}

export default PortfolioCard