import React from "react";
import RootComponent from "./Router";

function App() {
    return (
        <RootComponent />
    );
}

export default App;
