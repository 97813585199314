import React from 'react'
import './Header.css'
import { PopupButton } from '@typeform/embed-react'


// Assets import
import star from '../../../../assets/Star.webp';
import home from '../../../../assets/header-banner.webp';

function Header() {
  return (
    <>
        <div className="container header-wrapper my-5">
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 header-content">
                    <div className="star-wrapper" style={{justifyContent: "start"}}>
                        <img className="star" src={star} alt="Star" />
                        <p>Discover</p>
                    </div>
                    <h1>Turning Your Startup <span>Dreams into Reality</span></h1>
                    <p className="header-text">
                        We bridge the gap between entrepreneurial vision and technical execution. We're a full-stack software development company specializing in building impactful, enterprise-grade solutions for diverse industries. We support startups by providing them with the technical expertise and resources they need to succeed.
                    </p>
                    <div className="trust-btn-wrapper">
                        <PopupButton
                            id="hjTccK4p"
                            className="btn CTA"
                            hidden={{ foo: 'foo value', bar: 'bar value' }}
                        > Get Started </PopupButton>
                        <div className="trust-text">
                            <i className="fa-solid fa-star" style={{ color: "#FFC700", fontSize: "2.0rem", fontWeight: 'bold' }}></i>
                            <p>Trusted by 30+ Brands & Businesses</p>
                        </div>
                    </div>
                    <div className="container-fluid numbers">
                        <div className=" number-card">
                            <h3>25+</h3>
                            <p>MVPs Developed</p>
                        </div>
                        <div className=" number-card">
                            <h3>10+</h3>
                            <p>Scaled Up</p>
                        </div>
                        <div className=" number-card">
                            <h3>10+</h3>
                            <p>Platform Upgrades</p>
                        </div>
                    </div>

                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-3 header-banner">
                    <img src={home} alt="9exGen" />
                </div>
            </div>
        </div>
    </>
  )
}

export default Header