import React from "react";
import "./Privacy.css";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../Components/Footer/Footer";
import Contact from "./Contact";

const descriptions = [
 "This Privacy Policy describes how 9exGen-Solution Pvt Ltd (“we”, “us”, “our”) collects, uses, and protects the information you provide when using our products. We are committed to ensuring that your privacy is protected. By using our products, you agree to the terms of this Privacy Policy."
];

const benefits = [
  {
    title: "Information Collection",
    description:
      "When you register as an authorized user of our products, we collect certain information to provide you with relevant services. We do not share personally identifiable information with third parties unless you explicitly consent. We do not sell, rent, or lease any personally identifiable information.",
  },
  {
    title: "Server Log Data",
    description:
      "We collect Server Log Data, including your computer’s Internet Protocol (“IP”) address, browser type, pages visited, time and date of visit, time spent on pages, and other statistics. We may use third-party services such as Microsoft AppInsights for monitoring and analyzing website visitor actions.",
  },
  {
    title: "Cookies",
    description:
      "We use cookies to improve your site access and identify repeat visitors. Cookies help us enhance user experience by remembering your preferences and avoiding the need for you to log in multiple times. We may also use cookies to track and target user interests for site enhancement.",
  },
  {
    title: "Security",
    description:
      "We employ commercially acceptable measures to protect your Personal Information. However, please note that no method of transmission over the Internet or electronic storage is completely secure. While we strive to protect your information, we cannot guarantee absolute security.",
  },
  {
    title:"Changes to this Privacy Policy",
    description:"This Privacy Policy is effective as of Jan 15, 2024. We reserve the right to update or change this policy at any time. Any modifications will be effective immediately upon posting on this page. Please check this page periodically for updates. Your continued use of our products after any changes to this Privacy Policy constitutes acceptance of those changes."
  },
];
function Privacy() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead title="Privacy Policy" />
      <div className="container serviceslisting">
        <div className="col-lg-12 col-12 strategy">
          <div className="strategy-detail">
            {descriptions.map((desc, index) => (
              <h6 key={index}>{desc}</h6>
            ))}
          </div>
        </div>
        <div className="col-lg-12 col-12 benefit-detail">
          {benefits.map((item, index) => (
            <div key={index} className="benefit-content">
              <h1 className="title">{item.title}:</h1>
              <p className="description">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
      <Contact/>
      <Footer />
    </>
  );
}

export default Privacy;
