import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";
import "./Navbar.css";

// Assets import
import logo from "../../../assets/9exWhiteLogo.webp";
import whiteLogo from "../../../assets/9exbluelogo.webp";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const shouldBeScrolled = scrollPosition > 0;

      setIsScrolled(shouldBeScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    setActiveTab(currentPath);
    setDropdownOpen(false); 
  }, [location.pathname]);

  const handleTabClick = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropDown = (path) => {
    setActiveTab(path);
    navigate(path);
    setDropdownOpen(false);
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg nav-scroll ${
          isScrolled ? "scrolled" : ""
        }`}
      >
        <div className="container">
          <a className="logo" href="/">
            <img
              className="logo"
              src={`${isScrolled ? whiteLogo : logo}`}
              alt="9exgen"
              width="130"
              height="60"
            />
          </a>
          <button
            className="navbar-toggler"
            style={{ color: `${isScrolled ? "#fff" : "#000"}` }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar">
              <i className="fas fa-bars"></i>
            </span>
          </button>

          <div className="navbar-collapse collapse" id="navbar">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <p
                  className={`nav-link ${activeTab === "/" ? "active" : ""}`}
                  onClick={() => handleTabClick("/")}
                >
                  Home
                </p>
              </li>
              <li className="nav-item">
                <p
                  className={`nav-link ${
                    activeTab === "/about" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("/about")}
                >
                  About Us
                </p>
              </li>
              <li className="nav-item">
                <p
                  className={`nav-link ${
                    activeTab === "/services" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("/services")}
                >
                  Services
                </p>
              </li>
              <li className={`nav-item dropdown ${dropdownOpen ? "show" : ""}`}>
                <p
                  className={`nav-link dropdown-toggle ${
                    activeTab === "/resources" ? "active" : ""
                  }`}
                  onClick={handleDropdownToggle}
                  role="button"
                  aria-haspopup="true"
                  aria-expanded={dropdownOpen}
                >
                  Insights
                </p>
                <div className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
                  <p
                    className={`dropdown-item ${
                      activeTab === "/blogs" ? "active" : ""
                    }`}
                    onClick={() => handleDropDown("/blogs")}
                  >
                    Blogs
                  </p>
                  <p
                    className={`dropdown-item ${
                      activeTab === "/case-study" ? "active" : ""
                    }`}
                    onClick={() => handleDropDown("/case-study")}
                  >
                    Case Studies
                  </p>
                  <p
                    className={`dropdown-item ${
                      activeTab === "/testimonial" ? "active" : ""
                    }`}
                    onClick={() => handleDropDown("/testimonial")}
                  >
                    Testimonials
                  </p>
                  <p
                    className={`dropdown-item ${
                      activeTab === "/portfolio" ? "active" : ""
                    }`}
                    onClick={() => handleDropDown("/portfolio")}
                  >
                    Portfolio
                  </p>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <PopupButton
                  id="hjTccK4p"
                  className="btn quote-btn"
                  hidden={{ foo: "foo value", bar: "bar value" }}
                >
                  GET A QUOTE
                </PopupButton>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
