import React, { useState } from "react";
const ExpandableText = ({ children, isExpanded }) => {
  // const [isExpanded, setIsExpanded] = useState(false);

  // const toggleText = () => {
  //   setIsExpanded(!isExpanded);
  // };

  return (
    <div className="expandable-container">
      {/* <div className="content-header">
        {!isExpanded && (
          <div onClick={toggleText} className="toggle-button">
           <i className="fas fa-chevron-down"></i>
          </div>
        )}
      </div>
     
      {isExpanded && (
        <div onClick={toggleText} className="toggle-button">
           <i className="fas fa-chevron-up"></i>
        </div>
      )} */}
      <div className={`expandable-content ${isExpanded ? "expanded" : ""}`}>
        {children}
      </div>
    </div>
  );
};

function ServiceDescription({ descriptions, contents, mainImage, heading }) {
  const [expandedText, setExpandedText] = useState(null);
  const toggleText = (index) => {
    if (expandedText === index) {
      setExpandedText(null); // Collapse if already expanded
    } else {
      setExpandedText(index); // Expand the selected one
    }
  };
  

  return (
    <div className="container serviceslisting-wrapper">
      <div className="col-lg-12 col-12 strategy-content">
        <div className="strategy-detail">
          <h3>{heading}</h3>
          {descriptions.map((desc, index) => (
            <h6 key={index}>{desc}</h6>
          ))}
        </div>
      </div>
      <div className="row header-row" style={{ marginTop: "40px" }}>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-3 header-banner">
          <img
            src={mainImage}
            alt="Service Banner"
            style={{ objectFit: "cover", borderRadius: "20px" }}
          />
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 header-content">
          {contents.map((content, index) => (
            <div
              key={index}
              onClick={() => toggleText(index)}
              style={{ cursor: "pointer" }}
              className="content"
            >
              <div className="content-header">
                <img className="star-box" src={content.image} alt="Star" />
                <p>{content.title}</p>
                
              </div>
              <div className="con-con">
                  {expandedText == index && (
                    <div   onClick={() => toggleText(index)} className="toggle-button">
                    <i className="fas fa-chevron-up"></i>
                     
                    </div>
                  )}
                </div>
                <div className="con-con">
                {expandedText != index && (
                  <div   onClick={() => toggleText(index)} className="toggle-button">
                    <i className="fas fa-chevron-down"></i>
                  </div>
                )}
                </div>
              <ExpandableText isExpanded={expandedText == index}>
                <div>
                  <h6 className="detail">
                    {content.detail}
                  </h6>
                </div>
              </ExpandableText>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServiceDescription;
