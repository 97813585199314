import React from "react";
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";
import { useLocation } from "react-router-dom";

const descriptions = [
  "A state-of-the-art AI platform engineered to streamline the tech hiring process, focusing on enhancing efficiency and innovation in recruitment. FirstPassAI utilizes advanced algorithms and data-driven insights to simplify candidate selection, reduce hiring times, and match top talent with the right opportunities. The platform revolutionizes recruitment by providing a smarter, more effective approach to finding and securing tech talent.",
];

function FirstPassProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"FirstPass-AI"} />
      <Introduction
        src={project.imagePath}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default FirstPassProjectDetail;
