import React from "react";
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";
import { useLocation } from "react-router-dom";

const descriptions = [
  "An advanced multi-currency digital wallet designed to facilitate global transactions with ease. Travallet offers users seamless access to local markets and currency management while traveling. The platform streamlines financial interactions, ensuring a smooth and efficient experience for users navigating different currencies and financial systems around the world.",
];

function TravelletProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Travellet"} />
      <Introduction
        src={project.imagePath}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default TravelletProjectDetail;
