import React from "react";
import { useLocation } from "react-router-dom";
// import components
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";

const descriptions = [
  "A culinary-focused website dedicated to improving the cooking experience by offering a wide range of recipes, cooking tips, and kitchen tools. KitchenAra aims to inspire creativity in the kitchen and support home cooks in exploring new culinary techniques. The platform provides valuable resources and expert advice to make cooking enjoyable and accessible for all skill levels.",
];
function KitchenaraProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Kitchenara"} />
      <Introduction
        src={project.imagePath}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default KitchenaraProjectDetail;
