import React from 'react'
import './CaseStudyPage.css'
// Assets import
import Introduction from './Components/Introduction';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import BreadcrumbHead from '../../Components/BreadcrumbHead/BreadcrumbHead';
import Idea from './Components/Idea';
import Challange from './Components/Challange';
import Solution from './Components/Solution';
import Feature from './Components/Feature';

const descriptions = [
  "Our expertise spans across various platforms and technologies, ensuring we deliver high-quality, user-centric apps that meet your business objectives. From initial concept to deployment and maintenance, we provide end-to-end solutions tailored to your unique needs.",
];
const  idea=[
  "Our expertise spans across various platforms and technologies, ensuring we deliver high-quality, user-centric apps that meet your business objectives. From initial concept to deployment and maintenance, we provide end-to-end solutions tailored to your unique needs."
];
const  challange=[
  "Our expertise spans across various platforms and technologies, ensuring we deliver high-quality, user-centric apps that meet your business objectives. From initial concept to deployment and maintenance, we provide end-to-end solutions tailored to your unique needs."
];
const  solution=[
  "Our expertise spans across various platforms and technologies, ensuring we deliver high-quality, user-centric apps that meet your business objectives. From initial concept to deployment and maintenance, we provide end-to-end solutions tailored to your unique needs."
];
const data = [
  {
    id: 1,
    num: "01",
    title: "Excellence",
    subtitle:
      "We strive for the highest standards in everything we do.",
  },
  {
    id: 2,
    num: "02",
    title: "Integrity",
    subtitle:
      " We conduct our business with honesty and transparency.",
  },
  {
    id: 3,
    num: "03",
    title: "Innovation",
    subtitle:
      "We encourage creativity and the pursuit of new ideas.",
  },
  {
    id: 4,
    num: "04",
    title: "Teamwork",
    subtitle:
      "We achieve more when we work together.",
  },
  {
    id: 5,
    num: "05",
    title: "Customer Focus",
    subtitle:
      "We prioritize our clients’ needs and work diligently to exceed their expectations.",
  },
  {
    id: 6,
    num: "06",
    title: "Customer Focus",
    subtitle:
      "We prioritize our clients’ needs and work diligently to exceed their expectations.",
  },
];
function DukandarDetail() {
  return (
    <>
        <Navbar/>
        <BreadcrumbHead title={"Dukandar Online"}/>
        <Introduction  descriptions={descriptions} />
        <Idea idea={idea}/>
        <Challange challange={challange}/>
        <Solution solution={solution}/>
        <Feature
        title={"Feature of Dukandar Online"}
        subtitle={"Successfully enabling an institute to monitor the E.Q level of their students, the web app was able to foster a culture of developing high E.Q in students. The following features of the S.E.L.F app made it seamless for the staff of an organization to extract data"}
        data={data}/>
        <Footer/>
        
    </>
  )
}

export default DukandarDetail