import React from "react";
// assests 
import verified from "../../../assets/verify.webp";
import Laptop from "../../../assets/uiux.webp";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import ServiceDescription from "../../Services/Components/ServiceDescription";
import Benefits from "../../Services/Components/Benefits";
import WorkingProcess from "../../Services/Components/WorkingProcess";
import Footprint from "../../Components/Footprint/Footprint";
import Footer from "../../Components/Footer/Footer";

// Components Import

const descriptions = [
 "Our comprehensive UI-UX design services are crafted to help you create digital products that not only look stunning but also provide an intuitive and engaging user experience. We combine creativity with functionality to deliver designs that captivate users and drive business success."
];

const contents = [
  {
    title: "User Research and Insights",
    image: verified,
    detail:"Understanding your users is key to designing products they love. Our user research services include comprehensive methods such as surveys, interviews, and usability tests to gather in-depth insights into user behaviors, needs, and pain points. These insights guide our design process, ensuring user-centric solutions."
  },
  {
    title: "Information Architecture (IA)",
    image: verified,
    detail:"Effective content organization is crucial for a seamless user experience. Our IA services focus on structuring and organizing content in a logical and intuitive manner. We create clear, user-friendly navigation systems that help users find what they need quickly and easily."
  },
  {
    title: "Wireframing and Prototyping",
    image: verified,
    detail:"Visualize your product’s structure and functionality with our wireframing and prototyping services. We create detailed wireframes to map out the layout of each screen, followed by interactive prototypes that simulate the user experience. This allows for early testing and feedback before moving into full-scale development."
  },
  {
    title: "User Interface (UI) Design",
    image: verified,
    detail:"Bring your brand to life with our UI design services. Our designers craft visually appealing interfaces that are aligned with your brand identity and provide a consistent experience across all devices. We focus on creating designs that are not only beautiful but also easy to use."
  },
  {
    title: "User Experience (UX) Design",
    image: verified,
    detail:"Enhance user satisfaction with our UX design services. We focus on creating seamless and enjoyable user journeys, prioritizing usability and accessibility. Our goal is to design products that users find easy, intuitive, and delightful to use."
  },
  {
    title: "Usability Testing",
    image: verified,
    detail:"Validate your design with real users through our usability testing services. We conduct thorough testing sessions to identify any usability issues and gather valuable user feedback. This iterative process ensures that the final product meets user expectations and performs flawlessly."
  },
  {
    title: "Interaction Design",
    image: verified,
    detail:"Engage your users with dynamic interaction design. We design interactive elements that respond to user actions, making your product more engaging and intuitive. From animations to transitions, our designs enhance the overall user experience."
  },
  {
    title: "Design Systems and Style Guides",
    image: verified,
    detail:"Ensure consistency across your digital platforms with our design systems and style guides. We develop comprehensive design systems that include UI components, guidelines, and best practices. This ensures a cohesive look and feel across all your digital products."
  },
];
const benefits = [
  {
    title: "User-Centric Focus",
    description:
      "We prioritize the needs and preferences of your users, ensuring our designs are both intuitive and effective.",
  },
  {
    title: "Expertise and Experience",
    description:
      "Our team of skilled designers brings extensive experience and creativity to every project, delivering exceptional results.",
  },
  {
    title: "Collaborative Approach",
    description:
      "We believe in working closely with our clients, involving you throughout the design process to ensure your vision is realized.",
  },
  {
    title: "Innovative Design",
    description:
      "We stay ahead of the latest trends and technologies, providing innovative and forward-thinking design solutions.",
  },
  {
    title: "Data-Driven Decisions",
    description:
      "Our designs are informed by thorough research and user testing, ensuring they meet user needs and business goals.",
  },
  {
    title: "Comprehensive Solutions",
    description:
      "From initial research to final implementation, we offer end-to-end UI-UX design services that cover every aspect of the design process.",
  },
];
function UI() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead
        title="UI-UX Design"
        subtitile="Creating engaging and user-friendly interfaces that enhance usability and drive conversions. Let us bring your vision to life with expert design solutions."
      />
      <ServiceDescription
        descriptions={descriptions}
        contents={contents}
        mainImage={Laptop}
        heading={"Transforming Ideas into Engaging Digital Journeys"}
      />
      <Benefits benefits={benefits} />
      <WorkingProcess />
      <Footprint />
      <Footer />
    </>
  );
}

export default UI;
