import React from "react";

function IconCard({ num, title, subtitle }) {
  return (
    <div className="icon-card-process">
      <h6>{num}</h6>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </div>
  );
}

function Process() {
  const data = [
    {
      id: 1,
      num: "01",
      title: "Excellence",
      subtitle:
        "We strive for the highest standards in everything we do.",
    },
    {
      id: 2,
      num: "02",
      title: "Integrity",
      subtitle:
        " We conduct our business with honesty and transparency.",
    },
    {
      id: 3,
      num: "03",
      title: "Innovation",
      subtitle:
        "We encourage creativity and the pursuit of new ideas.",
    },
    {
      id: 4,
      num: "04",
      title: "Teamwork",
      subtitle:
        "We achieve more when we work together.",
    },
    {
      id: 5,
      num: "05",
      title: "Customer Focus",
      subtitle:
        "We prioritize our clients’ needs and work diligently to exceed their expectations.",
    },
  ];

  return (
    <>
      <div className="container-fluid process-wrapper">
        <p>Our Core Values</p>
        <div className="row">
          {/* <div className="col-lg-5 col-md-6 col-12">
            <div className="process-header">
              <h2>We Make Hiring Simple and Efficient</h2>
              <p>
              While maintaining transparency at the core, we look for change-makers like you!
              </p>
            </div>
          </div> */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 tech-process">
            {data.map((item) => (
              <IconCard
                key={item.id}
                num={item.num}
                title={item.title}
                subtitle={item.subtitle}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Process;
