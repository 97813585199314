import React from "react";
import { useLocation } from "react-router-dom";
// import components
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";

const descriptions = [
  "The leading global association dedicated to supporting overseas Asian women by fostering connections and promoting cultural heritage. Cross Culture Asia provides a platform for networking, community engagement, and business support, enhancing the professional and personal lives of its members. CCA is committed to empowering women and celebrating cultural diversity.",
];
const linkText =
  "Check CCA in celebrating diversity and strengthening bonds across the globe.";

function CCAProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Cross Culture Asia (CCA)"} />
      <Introduction
        src={project.imagePath}
        linkText={linkText}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default CCAProjectDetail;
