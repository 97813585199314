import React from 'react'
export function truncateString(str, num) {
    if (str) return str.length > num ? `${str.substring(0, num)}...` : str;
  }
function BlogBox({ data }) {
    return (
        <>
            <div className='col-lg-6 col-md-6 col-sm-6 col-12 blog-box'>
                <h3>{data.title}</h3>
                <h6>{truncateString(data.text,130)}</h6>
                <p>{data.published_date.split("T")[0]}</p>
            </div>
        </>
    )
}

export default BlogBox