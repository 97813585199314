import React, { useState } from "react";
import "./Opportunity.css";
import { useNavigate } from "react-router-dom";

function Question() {
    const navigate  = useNavigate()
  return (
    <>
      <div className="container-fluid col-lg-12 col-md-12 col-sm-12 col-12 question-wrapper">
        <div
          className="start-wrapper"
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems:'center',
            flexDirection: "column",
          }}
        >
          {/* <img className="star" src={star} alt="Star" /> */}
          <p>Questions About Joining 9exGen-Solution?</p>
          <h3 className="question-head">
            Nothing for you at this point? Worry not! Just drop your resume and
            we'll drop you a line once we have something interesting for you. To
            learn more about us visit our FAQs page!
          </h3>
          <button onClick={()=> navigate('/faq')} type="submit" className="btn">
          Frequently Asked Questions
              </button>
        </div>
      </div>
    </>
  );
}

export default Question;
