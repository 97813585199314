import React from "react";

// Assets import
import star from "../../../assets/Star.webp";
import mvp from "../../../assets/mvpData.webp";

function ServiceContent() {
  return (
    <>
      <div className="serivce-data">
        <div
          className="star-wrapper"
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          <img className="star" src={star} alt="Star" />
          <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Our Culture</p>
        </div>
        <div className="data-heading">
          <h3>Prototype & MVP Development for Startups</h3>
          <p>
            We craft innovative web & mobile solutions that engage users, drive
            results, and empower your business. From ideation to launch, we
            offer a comprehensive suite of services to bring your digital vision
            to life
          </p>
        </div>
        <div className="container">
          <div className="col-lg-8 col-md-12 col-sm-12 col-12 data-banner">
            <img src={mvp} alt="9exGen" />
          </div>
        </div>
        <div className="data-detail">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            ullamcorper mattis lorem non. Ultrices praesent amet ipsum justo
            massa. Eu dolor aliquet risus gravida nunc at feugiat consequat
            purus. Non massa enim vitae duis mattis. Vel in ultricies vel
            fringilla.
          </p>
          <p>
            Sagittis et eu at elementum, quis in. Proin praesent volutpat
            egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac.
            Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur
            ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim
            massa erat cursus vulputate gravida id. Sed quis auctor vulputate
            hac elementum gravida cursus dis. Lectus id duis vitae porttitor
            enim gravida morbi.
          </p>
          <p>
            Eu turpis posuere semper feugiat volutpat elit, ultrices
            suspendisse. Auctor vel in vitae placerat. Suspendisse maecenas ac
            donec scelerisque diam sed est duis purus
          </p>
        </div>
      </div>
    </>
  );
}

export default ServiceContent;
