import React from 'react'
import '../main.css'
import './Blogs.css'

// Components Import
import WhatsappIcon from '../Components/WhatsappIcon/WhatsappIcon'
import Navbar from '../Components/Navbar/Navbar'
import Footprint from '../Components/Footprint/Footprint'
import Footer from '../Components/Footer/Footer'
import BlogsListing from './Components/BlogsListing'
import BreadcrumbHead from '../Components/BreadcrumbHead/BreadcrumbHead'

function Blogs() {

    return (
        <>
            <WhatsappIcon />
            <Navbar />
            <BreadcrumbHead title={"Blogs"}/>
            <BlogsListing />
            <Footprint />
            <Footer />
        </>
    )
}

export default Blogs