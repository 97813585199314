import React, { useState } from "react";
import "./FAQ.css";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../Components/Footer/Footer";
import { PopupButton } from "@typeform/embed-react";

// Assets import
import star from "../../../assets/Star.webp";
import home from "../../../assets/header-banner.webp";

const contents = [
  {
    title: "What kind of support does 9exGen Solutions offer to startups?",
    image: star,
    detail:
      "We provide comprehensive support to startups, including but not limited to software development, product ideation, market analysis, prototype development, and scaling strategies. We tailor our services to meet the specific needs and goals of each startup we work with.",
  },
  {
    title: "How experienced is the team at 9exGen Solutions?",
    image: star,
    detail:
      "Our team is comprised of highly skilled professionals with years of experience in the tech industry. We have worked with startups of all sizes and across various sectors, bringing a wealth of knowledge and expertise to every project we undertake.",
  },
  {
    title: "Can 9exGen Solutions help with funding or investment opportunities for startups?",
    image: star,
    detail:
      "While 9exGen Solutions does not directly offer funding or investment opportunities, we can provide valuable guidance and connections to help startups navigate the fundraising process. Our network of partners and investors can offer valuable insights and potential opportunities for funding.",
  },
  {
    title: "What is the typical timeline for a project with 9exGen Solutions?",
    image: star,
    detail:
      "The timeline for a project with us can vary depending on the scope and complexity of the project. We work closely with startups to establish realistic timelines and milestones, ensuring that projects are completed on time and within budget.",
  },
  {
    title: "Does 9exGen Solutions provide ongoing support after the project is completed?",
    image: star,
    detail:
      "Yes, we are committed to providing ongoing support and maintenance for our clients. We offer post-launch support services, including bug fixes, updates, and technical assistance, to ensure that our clients' software solutions continue to perform optimally.",
  },
  {
    title: "What technologies does 9exGen Solutions specialize in?",
    image: star,
    detail:
      "We specialize in a wide range of technologies, including but not limited to web development, mobile app development, cloud computing, artificial intelligence, machine learning, blockchain, and IoT (Internet of Things). We stay up-to-date with the latest technologies and trends to deliver cutting-edge solutions to our clients.",
  },
  {
    title: "Is 9exGen Solutions open to partnerships or collaborations?",
    image: star,
    detail:
      "Yes, we are always open to exploring partnerships and collaborations with other companies, startups, and organizations. Whether it's joint ventures, strategic alliances, or co-development projects, we welcome opportunities to collaborate and create value together.",
  },
];
function FAQ() {
    const [expandedIndexes, setExpandedIndexes] = useState([]);

    const toggleAnswer = (index) => {
      setExpandedIndexes((prevIndexes) => {
        if (prevIndexes.includes(index)) {
          return prevIndexes.filter((i) => i !== index);
        } else {
          return [...prevIndexes, index];
        }
      });
    };
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead title="Frequently Asked Questions" />
      <div className="container header-wrap my-5">
        <div className="row">
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 header-cont">
            <div className="head" style={{ justifyContent: "start" }}>
              <p>FAQ</p>
            </div>
            <h1>
            Explore, Inquire, Understand
            </h1>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 col-12 my-3 header-ban">
            {contents.map((content, index) => (
              <div key={index} className="faq-item">
                <div className="question" onClick={() => toggleAnswer(index)}>
                    <p>
                        {content.title}
                    </p>

                    <i className={`fas ${expandedIndexes.includes(index) ? 'fa-minus' : 'fa-plus'}`}></i>

                </div>
                <div
                  className={`answer ${expandedIndexes.includes(index) ? 'expanded' : ''}`}
                >
                  <p>{content.detail}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FAQ;
