import React from "react";
import { useLocation } from "react-router-dom";
// import components
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";

const descriptions = [
  "A modern solution designed to enhance embassy services for Kazakhstan by providing seamless connectivity and efficient service management. The app improves user experience with streamlined access to essential services and information. Kazakhstan App ensures that interactions with the embassy are convenient, effective, and well-organized, supporting a better service experience for users.",
];

function KazakhstanProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Kazakhstan"} />
      <Introduction
        src={project.imagePath}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default KazakhstanProjectDetail;
