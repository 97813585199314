import React from 'react'
import { FaWhatsapp } from 'react-icons/fa';
import './WhatsappIcon.css';

function WhatsappIcon() {

    const openWhatsApp = () => {
        window.open('https://api.whatsapp.com/send?phone=923354899894', '_blank');
    };

    return (
        <>
            <div className="whatsapp-floating-icon" onClick={openWhatsApp}>
                <FaWhatsapp />
            </div>
        </>
    )
}

export default WhatsappIcon
