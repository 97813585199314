import React from "react";
import "./Introduction.css";
import google from "../../../../../assets/Googleplay.webp";
import apple from "../../../../../assets/Appstore.webp";

function Introduction({
  descriptions,
  src,
  linkText,
  linkUrl,
  appStoreUrl,
  googlePlayUrl,
}) {
  const isWebApp = linkUrl && !appStoreUrl && !googlePlayUrl; 
  const isMobileApp = appStoreUrl || googlePlayUrl;

  return (
    <div className="container introduction-wrapper my-5">
      <div className="row introduction-head">
        <div className="col-lg-5 col-md-12 col-sm-12 col-12 introduction-content">
          <h1>Introduction</h1>
          {descriptions.map((desc, index) => (
            <p className="introduction-text" key={index}>
              {desc}
            </p>
          ))}

          {/* Display the "Visit Me" button if it's a web app */}
          {isWebApp && (
            <div>
              <p className="introduction-text">{linkText}</p>
              <a
                className="introduction-link"
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <button type="submit">Visit the Website</button>
              </a>
            </div>
          )}

          {/* Display App Store and Google Play buttons if it's a mobile app */}
          {isMobileApp && (
            <div className="store-buttons">
              <p className="introduction-text">{linkText}</p>

              <a
                href={appStoreUrl || "#"}
                target={appStoreUrl ? "_blank" : "_self"}
                rel="noopener noreferrer"
                className={`store-button ${!appStoreUrl ? "disabled-button" : ""}`}
                onClick={(e) => {
                  if (!appStoreUrl) e.preventDefault();
                }}
              >
                <img src={apple} alt="App Store" />
              </a>

              <a
                href={googlePlayUrl || "#"}
                target={googlePlayUrl ? "_blank" : "_self"}
                rel="noopener noreferrer"
                className={`store-button ${!googlePlayUrl ? "disabled-button" : ""}`}
                onClick={(e) => {
                  if (!googlePlayUrl) e.preventDefault();
                }}
              >
                <img src={google} alt="Google Play" />
              </a>
            </div>
          )}

          {/* Display disabled buttons when neither web nor mobile app conditions are met */}
          {!isWebApp && !isMobileApp && (
            <div className="store-buttons">
              <p className="introduction-text">{linkText}</p>

              <a
                href="#"
                className="store-button disabled-button"
                onClick={(e) => e.preventDefault()}
              >
                <img src={apple} alt="App Store" />
              </a>

              <a
                href="#"
                className="store-button disabled-button"
                onClick={(e) => e.preventDefault()}
              >
                <img src={google} alt="Google Play" />
              </a>
            </div>
          )}
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-3 introduction-banner">
          <img src={src} alt="Project" />
        </div>
      </div>
    </div>
  );
}

export default Introduction;
