import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Marketplace Home Page
import Home from "./Screens/Home/Home";
import About from "./Screens/About/About";
import Blogs from "./Screens/Blog/Blogs";
import BlogDetails from "./Screens/Blog/Components/BlogDetails";
import Services from "./Screens/Services/Services";
// Services Detail Page
import ServicesDetail from "./Screens/Services/ServicesDetail";
import Web from "./Screens/ServicesDetail/WebDevelopment/Web";
import Digital from "./Screens/ServicesDetail/DigitalMarketing/Digital";
import MVP from "./Screens/ServicesDetail/MVPDevelopment/MVP";
import QualityAsurance from "./Screens/ServicesDetail/QualityAssurance/QualityAsurance";
import StartupExp from "./Screens/ServicesDetail/StartupExperience/StartupExp";
import Technology from "./Screens/ServicesDetail/TechDevelopemt/Technology";
import UI from "./Screens/ServicesDetail/UIDesign/UI";
import AppDevelopment from "./Screens/ServicesDetail/AppDevelopment/AppDevelopment";
// Privacy Policy Page
import Privacy from "./Screens/Insights/PrivacyPolicy/Privacy";
import CaseStudyPage from "./Screens/Insights/CaseStudy/CaseStudyPage";
import FAQ from "./Screens/Insights/Faq/FAQ";
import Career from "./Screens/Insights/Career/Career";
import Opportunity from "./Screens/Insights/Opportunity/Opportunity";
import TamwwilDetail from "./Screens/Insights/CaseStudyDetail/TamwwilDetail";
import BizifiDetail from "./Screens/Insights/CaseStudyDetail/BizifiDetail";
import DukandarDetail from "./Screens/Insights/CaseStudyDetail/DukandarDetail";
import KazakhstanDetail from "./Screens/Insights/CaseStudyDetail/KazakhstanDetail";
import Portfolio from "./Screens/Insights/Portfolio/Portfolio";
import Testimonial from "./Screens/Insights/Testimonial/Testimonial";
import ScrollToTop from "./ScrollToTop";
import TamwwilProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/TamwwilProjectDetail";
import YusraProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/YusraProjectDetail";
import NoSugarProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/NoSugarProjectDetail";
import CCAProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/CCAProjectDetail";
import BizifiProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/BizifiProjectDetail";
import SuperLoyalProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/SuperLoyalProjectDetail";
import KitchenaraProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/KitchenaraProjectDetail";
import DukandarOnlineProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/DukandarOnline";
import BuiltopProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/BuiltopProjectDetail";
import PetOasisProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/PetOasisProjectDetail";
import FirstPassProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/FirstPassProjectDetail";
import TravelletProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/TravelletProjectDetail";
import HiCureProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/HiCureProjectDetail";
import CCADetail from "./Screens/Insights/CaseStudyDetail/CCADetail";
import YusraDetail from "./Screens/Insights/CaseStudyDetail/YusraDetail";
import NoSugarDetail from "./Screens/Insights/CaseStudyDetail/NoSugarDetail";
import KitchenaraDetail from "./Screens/Insights/CaseStudyDetail/KitchenaraDetail";
import TravelletDetail from "./Screens/Insights/CaseStudyDetail/TravelletDetail";
import HiCureDetail from "./Screens/Insights/CaseStudyDetail/HiCureDetail";
import PetOasisDetail from "./Screens/Insights/CaseStudyDetail/PetOasisDetail";
import BuiltopDetail from "./Screens/Insights/CaseStudyDetail/BuiltopDetail";
import SuperloyalDetail from "./Screens/Insights/CaseStudyDetail/SuperloyalDetail";
import FirstPassDetail from "./Screens/Insights/CaseStudyDetail/FirstPassDetail";
import KazakhstanProjectDetail from "./Screens/Home/Sections/Portfolio/ProjectDetail/KazakhstanProjectDetail";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ScrollToTop>
        <Home/>
      </ScrollToTop>
    ),
  },
  {
    path: "about",
    element: (
      <ScrollToTop>
        <About />
      </ScrollToTop>
    ),
  },
  {
    path: "blogs",
    element: (
      <ScrollToTop>
        <Blogs />
      </ScrollToTop>
    ),
  },
  {
    path: "blog/:slug",
    element: (
      <ScrollToTop>
        <BlogDetails/>
      </ScrollToTop>
    ),
  },

  // Services Pages

  {
    path: "services",
    element: (
      <ScrollToTop>
        <Services />
      </ScrollToTop>
    ),
  },
  {
    path: "service-detail",
    element: (
      <ScrollToTop>
        <ServicesDetail />
      </ScrollToTop>
    ),
  },

  // Services Detail pages

  {
    path: "services/web-development",
    element: (
      <ScrollToTop>
        <Web />
      </ScrollToTop>
    ),
  },
  {
    path: "services/app-development",
    element: (
      <ScrollToTop>
        <AppDevelopment />
      </ScrollToTop>
    ),
  },
  {
    path: "services/digital-marketing",
    element: (
      <ScrollToTop>
        <Digital />
      </ScrollToTop>
    ),
  },
  {
    path: "services/mvp-development",
    element: (
      <ScrollToTop>
        <MVP />
      </ScrollToTop>
    ),
  },
  {
    path: "services/quality-assurance",
    element: (
      <ScrollToTop>
        <QualityAsurance />
      </ScrollToTop>
    ),
  },
  {
    path: "services/startup-experience",
    element: (
      <ScrollToTop>
        <StartupExp />
      </ScrollToTop>
    ),
  },
  {
    path: "services/technology-development",
    element: (
      <ScrollToTop>
        <Technology />
      </ScrollToTop>
    ),
  },
  {
    path: "services/UI-UX-design",
    element: (
      <ScrollToTop>
        <UI />
      </ScrollToTop>
    ),
  },

  // Privacy Pages

  {
    path: "privacy-policy",
    element: (
      <ScrollToTop>
        <Privacy />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study",
    element: (
      <ScrollToTop>
        <CaseStudyPage />
      </ScrollToTop>
    ),
  },
  {
    path: "faq",
    element: (
      <ScrollToTop>
        <FAQ />
      </ScrollToTop>
    ),
  },
  {
    path: "career",
    element: (
      <ScrollToTop>
        <Career />
      </ScrollToTop>
    ),
  },
  {
    path: "portfolio",
    element: (
      <ScrollToTop>
        <Portfolio />
      </ScrollToTop>
    ),
  },
  {
    path: "testimonial",
    element: (
      <ScrollToTop>
        <Testimonial />
      </ScrollToTop>
    ),
  },
  {
    path: "career/job-positions",
    element: (
      <ScrollToTop>
        <Opportunity />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/tamwwil-detail",
    element: (
      <ScrollToTop>
        <TamwwilDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/bizifi-detail",
    element: (
      <ScrollToTop>
        <BizifiDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/yusra-detail",
    element: (
      <ScrollToTop>
        <YusraDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/the-nosugar-detail",
    element: (
      <ScrollToTop>
        <NoSugarDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/kitchenara-detail",
    element: (
      <ScrollToTop>
        <KitchenaraDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/hicure-detail",
    element: (
      <ScrollToTop>
        <HiCureDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/petoasis-detail",
    element: (
      <ScrollToTop>
        <PetOasisDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/builtop-detail",
    element: (
      <ScrollToTop>
        <BuiltopDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/superloyal-detail",
    element: (
      <ScrollToTop>
        <SuperloyalDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/firstpass-ai-detail",
    element: (
      <ScrollToTop>
        <FirstPassDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/travellet-detail",
    element: (
      <ScrollToTop>
        <TravelletDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/dukandar-online-detail",
    element: (
      <ScrollToTop>
        <DukandarDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/cca-detail",
    element: (
      <ScrollToTop>
        <CCADetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/kazakhstan-detail",
    element: (
      <ScrollToTop>
        <KazakhstanDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/tamwwil-project-detail",
    element: (
      <ScrollToTop>
        <TamwwilProjectDetail />
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/yusra-project-detail",
    element: (
      <ScrollToTop>
        <YusraProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/kazakhstan-project-detail",
    element: (
      <ScrollToTop>
        <KazakhstanProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/the-nosugar-project-detail",
    element: (
      <ScrollToTop>
        <NoSugarProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/cca-project-detail",
    element: (
      <ScrollToTop>
        <CCAProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/bizifi-project-detail",
    element: (
      <ScrollToTop>
        <BizifiProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/superloyal-project-detail",
    element: (
      <ScrollToTop>
        <SuperLoyalProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/kitchenara-project-detail",
    element: (
      <ScrollToTop>
        <KitchenaraProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/dukandaronline-project-detail",
    element: (
      <ScrollToTop>
        <DukandarOnlineProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/builtop-project-detail",
    element: (
      <ScrollToTop>
        <BuiltopProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/petoasis-project-detail",
    element: (
      <ScrollToTop>
        <PetOasisProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/firstpassai-project-detail",
    element: (
      <ScrollToTop>
        <FirstPassProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/travellet-project-detail",
    element: (
      <ScrollToTop>
        <TravelletProjectDetail/>
      </ScrollToTop>
    ),
  },
  {
    path: "case-study/hicure-project-detail",
    element: (
      <ScrollToTop>
        <HiCureProjectDetail/>
      </ScrollToTop>
    ),
  },
]);

const RootComponent = () => {
  return <RouterProvider router={router} />;
};

export default RootComponent;
