import React, { useState } from "react";
import "./Testimonial.css";
import { useNavigate } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";

function ContactUs() {
    const navigate  = useNavigate()
  return (
    <>
      <div className="container-fluid col-lg-12 col-md-12 col-sm-12 col-12 contactus-wrapper">
        <div
          className="init-wrapper col-lg-12 col-md-12 col-sm-12 col-12" 
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems:'center',
            flexDirection: "column",
          }}
        >
          {/* <img className="star" src={star} alt="Star" /> */}
          <p>Have A Project In Mind ?</p>
          <h3 className="contactus-head">
          Feel Free To Contact Us <br/> We ' ll get back at you ASAP
          </h3>
          {/* <button onClick={()=> navigate('/faq')} type="submit" className="btn">
          Contact Us
              </button> */}
              <PopupButton
                  id="hjTccK4p"
                  className="btn quote-btn"
                  hidden={{ foo: "foo value", bar: "bar value" }}
                >
                  Contact Us
                </PopupButton>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
