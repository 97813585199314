import React from 'react'
import "./Strategy.css"

function StrategyIconBox({ title, imagePath }) {
    return (
        <>
            <div className='strategyIcon-box'>
                <img src={imagePath} style={{height: "40px", width: "auto"}} alt='strategy-icon' />
                <p>{title}</p>
            </div>
        </>
    )
}

export default StrategyIconBox