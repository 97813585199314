import React from "react";
import "../Services.css";

function ServiceBox({ title, img,onClick }) {
  return (
    <>
      <div className="col-lg-3 col-md-3 col-sm-12 col-12 service-box"  onClick={onClick}>
        <img className="service-img" src={img} alt="service" />
        <h3>{title}</h3>
        <p>
          Explore <i className="fa-solid fa-arrow-right"></i>
        </p>
      </div>
    </>
  );
}

export default ServiceBox;
