import React from "react";
import { useLocation } from "react-router-dom";
// import components
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";

const descriptions = [
  "A comprehensive website offering a diverse range of high-quality services and products, committed to delivering exceptional customer satisfaction. Yusra is designed to cater to various needs within its industry, providing a seamless user experience and maintaining a strong focus on excellence and reliability. The platform stands out for its commitment to quality and customer-centric approach.",
];

function YusraProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Yusra"} />
      <Introduction
        src={project.imagePath}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default YusraProjectDetail;
