import React from 'react'
import './Portfolio.css'

import { Widget } from '@typeform/embed-react'

// Assets import
import email from '../../../assets/contact-icon-one.webp';
import phone from '../../../assets/contact-icon-two.webp';
import address from '../../../assets/contact-icon-three.webp';
import social from '../../../assets/contact-icon-four.webp';

function Partner() {
    return (
        <>
            <div className="container-fluid partner-wrapper">
                <div className="row">
                    <div className="col-lg-6 col-md-12 partner-content">
                        <h3>Partner with us</h3>
                        <p> Elevate Your Digital Presence: Partner with Us for a Transformative Experiences </p>
                        <div className="container my-5">
                            <div className="row social-wrapper">
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 social my-3">
                                    <img src={email} alt="icon" />
                                    <h4>Email</h4>
                                    <p>sales@9exgen.com</p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 social my-3">
                                    <img src={phone} alt="icon" />
                                    <h4>Phone</h4>
                                    <p>+92 335 4899894</p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 social my-3">
                                    <img src={address} alt="icon" />
                                    <h4>Office</h4>
                                    <p>Johar Town, Lahore, Pakistan</p>
                                    <p>The Garage, Riyadh, Saudi Arabia</p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 social my-3">
                                    <img src={social} alt="icon" />
                                    <h4>Socials</h4>
                                    <div className="social-icons">
                                        <a href='https://www.facebook.com/9exGen?mibextid=opq0tG'><i className="fa-brands fa-facebook-f"></i></a>
                                        {/* <a href='/'><i className="fa-brands fa-twitter"></i></a> */}
                                        <a href='https://www.linkedin.com/company/9exgen-solutions/'> <i className="fa-brands fa-linkedin"></i></a>
                                        {/* <a href='/'><i className="fa-brands fa-instagram"></i></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 partner-form">
                        <Widget id="hwX3TYgF" style={{height: "530px"}} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Partner