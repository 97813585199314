import React, { useState } from "react";

function ServiceCard({ title, img, flipDetail,onClick }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleMouseEnter = () => {
    setIsFlipped(true);
  };

  const handleMouseLeave = () => {
    setIsFlipped(false);
  };

  return (
    <>
      <div
        className={`col-lg-3 col-md-3 col-sm-12 col-12 service-card ${
          isFlipped ? "flipped" : ""
        }`}
        style={{
          backgroundImage: isFlipped ? "none" : `url(${img})`,
          backgroundColor: isFlipped ? "#04153A" : "transparent",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      >
        <div className="card-content">
          {isFlipped ? (
            <>
              <h3>{title}</h3>
              <p>{flipDetail}</p>
            </>
          ) : (
            <p>{title}</p>
          )}
        </div>
      </div>
    </>
  );
}

export default ServiceCard;
