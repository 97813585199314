import React from 'react'

import ServiceCard from './ServiceCard'
import { PopupButton } from '@typeform/embed-react'

// Assets import
import star from '../../../assets/Star.webp';
import mvp from '../../../assets/mvp.webp';
import app from '../../../assets/app.webp';
import marketing from '../../../assets/marketing.webp';
import uiuxdesign from '../../../assets/uiux.webp';
import TechDev from '../../../assets/TechDev.webp';
import quality from '../../../assets/qa.webp';
import web from '../../../assets/web.webp';
import startups from '../../../assets/leads.webp';
import { useNavigate } from "react-router-dom";

function ServicesListing() {
  const navigate = useNavigate();

    return (
        <>
            <div className="container serviceslisting-wrapper">
                <div className="row">
                    <div className="col-12">
                        <div className="star-wrapper" style={{ justifyContent: "center" }}>
                            <img className="star" src={star} alt="Star" />
                            <p style={{fontSize: "1.6rem", fontWeight: "bold" }} >Services</p>
                        </div>
                        <h3 className='section-heading'>Prototype & MVP Development for Startups</h3>
                        <p className='section-text'style={{ paddingBottom: "20px" }}>We craft innovative web and mobile solutions that engage users, drive results, and empower your
                            business. From ideation to launch, we offer a comprehensive suite of services to bring your digital
                            vision to life
                        </p>
                    </div>
                </div>
                <div className="row gap-5 mb-5" style={{ display: "flex", justifyContent: "center" }}>
                    <ServiceCard title="MVP Development" img={mvp} flipDetail="Validate your ideas and get to market faster with rapid prototyping and MVP Development." onClick={()=>{navigate("/services/mvp-development")}}/>
                    <ServiceCard title="App Development" img={app} flipDetail="Agile methodologies for efficient app development and quick delivery" onClick={()=> {navigate("/services/app-development")}}/>
                    <ServiceCard title="Digital Marketing" img={marketing} flipDetail="Comprehensive digital marketing solutions for a competitive edge." onClick={()=>navigate("/services/digital-marketing")} />
                    <ServiceCard title="UI-UX Design" img={uiuxdesign} flipDetail="Elevate user engagement with beautiful and intuitive UI-UX design." onClick={()=>navigate("/services/UI-UX-design")}/>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12 mvp-card">
                        <h2>Are You Ready to build your MVP?</h2>
                        <PopupButton
                            id="hjTccK4p"
                            className="btn CTA"
                            hidden={{ foo: 'foo value', bar: 'bar value' }}
                        > Get Started </PopupButton>
                    </div>
                    <ServiceCard title="Technology Development" img={TechDev} flipDetail="Custom software development to meet specific organizational needs." onClick={()=>navigate("/services/technology-development")} />
                    <ServiceCard title="Quality Assurance" img={quality} flipDetail="Ensure flawless user experience through rigorous quality assurance testing.." onClick={()=>navigate("/services/quality-assurance")} />
                    <ServiceCard title="Web Development" img={web} flipDetail="Front-end and back-end development for full-stack capabilities." onClick={()=>navigate("/services/web-development")} />
                    <ServiceCard title="Startup Experiences" img={startups} flipDetail="Strategic planning for sustainable and scalable business models" onClick={()=>navigate("/services/startup-experience")} />
                </div>
            </div>
        </>
    )
}

export default ServicesListing