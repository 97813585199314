import React from 'react'
import '../main.css'

// Components Import
import WhatsappIcon from '../Components/WhatsappIcon/WhatsappIcon'
import Navbar from '../Components/Navbar/Navbar'
import BlogsContainer from '../Blog/Components/BlogsContainer'
import Contact from '../Components/Contact/Contact'
import Footprint from '../Components/Footprint/Footprint'
import Footer from '../Components/Footer/Footer'

// Sections Import
import Story from './Sections/Story/Story'
import Vision from './Sections/Vision/Vision'
import Culture from './Sections/Culture/Culture'

function About() {
    return (
        <>
            <WhatsappIcon />
            <Navbar />
            <Story />
            <Vision />
            <Culture />
            <Contact />
            <BlogsContainer />
            <Footprint />
            <Footer />
        </>
    )
}

export default About