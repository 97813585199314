import React from "react";

// Assets import
import star from "../../../assets/Star.webp";
import mvp from "../../../assets/mvp.webp";
import app from "../../../assets/app.webp";
import marketing from "../../../assets/marketing.webp";
import uiuxdesign from "../../../assets/uiux.webp";
import TechDev from "../../../assets/TechDev.webp";
import quality from "../../../assets/qa.webp";
import web from "../../../assets/web.webp";
import startups from "../../../assets/leads.webp";
import ServiceBox from "./ServiceBox";
import { useNavigate } from "react-router-dom";

function ServicesDetailListing() {
  const navigate = useNavigate();
  return (
    <>
      <div className="container servicesdetail-listing">
        <div className="row">
          <div className="col-12">
            <div className="star-wrapper" style={{ justifyContent: "center" }}>
              <img className="star" src={star} alt="Star" />
              <p style={{ fontSize: "1.6rem", fontWeight: "bold" }}>Services</p>
            </div>
            <h3 className="section-heading">
              Prototype & MVP Development for Startups
            </h3>
            <p className="section-text" style={{ paddingBottom: "20px" }}>
              We craft innovative web and mobile solutions that engage users,
              drive results, and empower your business. From ideation to launch,
              we offer a comprehensive suite of services to bring your digital
              vision to life
            </p>
          </div>
        </div>
        <div
          className="row gap-5 mb-5"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ServiceBox
            title="Web Development"
            img={web}
            onClick={()=>navigate("/services/web-development")}
          />
          <ServiceBox title="MVP Development" img={mvp} onClick={()=>{navigate("/services/mvp-development")}} />
          <ServiceBox title="App Development" img={app} onClick={()=> {navigate("/services/app-development")}}/>
          <ServiceBox title="Digital Marketing" img={marketing} onClick={()=>navigate("/services/digital-marketing")} />
          <ServiceBox title="UI-UX Design" img={uiuxdesign} onClick={()=>navigate("/services/UI-UX-design")} />
          <ServiceBox title="Technology Development" img={TechDev} onClick={()=>navigate("/services/technology-development")} />
          <ServiceBox title="Quality Assurance" img={quality} onClick={()=>navigate("/services/quality-assurance")}/>
          <ServiceBox title="Startup Experiences" img={startups} onClick={()=>navigate("/services/startup-experience")} />
        </div>
      </div>
    </>
  );
}

export default ServicesDetailListing;
