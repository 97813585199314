import React, { useState } from 'react'
import "./Strategy.css"

// Components Import
import StrategyCard from './StrategyCard';
import StrategyIconBox from './StrategyIconBox';

// Assets import
import star from '../../../../assets/Star.webp';
import busniess from '../../../../assets/bussniess.webp';
import explore from '../../../../assets/uiux-explore.webp';
import service from '../../../../assets/service.webp';
import appdev from '../../../../assets/appdev.webp';
import bloc from '../../../../assets/blockchain.webp';
import iphone from '../../../../assets/iphone.webp';
import andriod from '../../../../assets/andriod.webp';
import flutter from '../../../../assets/flutter.webp';
import react from '../../../../assets/react.webp';
import Enterprise from '../../../../assets/Enterprise.webp';
import AIML from '../../../../assets/AI-ML.webp';
import ARVR from '../../../../assets/ARVR.webp';
import sd from '../../../../assets/SD.webp';
import block from '../../../../assets/block.webp';
import smart from '../../../../assets/smart.webp';
import endToEnd from '../../../../assets/endToEnd.webp';
import ieo from '../../../../assets/ieo.webp';
import enterprise from '../../../../assets/carbon_enterprise.webp';

function Strategy() {

    const [activeStrategyCard, setActiveStrategyCard] = useState(1);

    const strategyDetails = [
        {
            id: 1,
            heading: "Validate & Prototype",
            img : service,
            title: "We bridge the gap between vision and reality",
            subtitle: "Discover the Power of Visualization for Your Ideas",
            detail: "Embark on your business journey confidently with our seasoned team and our in-house design studio. Whether it's researching your industry, mapping out your business strategy, or creating a dynamic prototype – we have got every step covered to ensure your success.",
            icons: [
                { path: explore, title: "Industry Research" },
                { path: busniess, title: "Business Plan" },
                { path: explore, title: "UI-UX Designing" },
                { path: busniess, title: "Prototyping & Wireframing" },
            ],
        },
        {
            id: 2,
            heading: "Custom Development",
            img : appdev,
            title: "Custom Mobile and Web Development",
            subtitle: "Crafting Triumphs for Your Mobile & Digital Venture – Where Success Takes Root!",
            detail: "Unleash Your Customized Mobile App and Web Solutions! Building robust, scalable, and user-friendly applications for iOS, Android, Flutter, and React Native. Tailored to Your Unique Needs.",
            icons: [
                { path: iphone, title: "Iphone APP Development" },
                { path: andriod, title: "Andriod APP Development" },
                { path: flutter, title: "Flutter APP Development" },
                { path: react, title: "ReactNative  APP Development" },
            ],
        },
        {
            id: 3,
            heading: "Enterprise Software",
            img : enterprise,
            title: "Enterprise Software Development",
            subtitle: "Seamless Solutions, Infinite Possibilities with Our Expert Software Development Services",
            detail: "Empowering Your Market Dominance: Gain the Competitive Edge with Our Customized Solutions. Unleash the Power of Top-tier Technology on Our New Website - Your Gateway to Tailor-Made Software Solutions for Boosted Revenues and Profits!",
            icons: [
                { path: Enterprise, title: "Enterprise APP Development" },
                { path: AIML, title: "Business AI-ML Development" },
                { path: ARVR, title: "AR VR Development" },
                { path: sd, title: "Software Development" },
            ],
        },
        {
            id: 4,
            heading: "AI/ML Solutions",
            img : bloc,
            title: "AI/ML Development",
            subtitle: "Empowering Your Business with Adaptive AI Technologies",
            detail: "Our software incorporates AI features, offering intelligent and automated solutions. Covering machine learning, computer vision, natural language processing (NLP), forecasting, and optimization, our AI technologies adapt to various environments and scale to address evolving business requirements.",
            icons: [
                { path: block, title: "Blockchain app consultation" },
                { path: smart, title: "Small contracts development" },
                { path: endToEnd, title: "End-to-end DApp solution" },
                { path: ieo, title: "IEO & ICO services" },
            ],
        },
    ];

    const handleStrategyCardClick = (strategyTitle) => {
        setActiveStrategyCard(strategyTitle);
    };

    return (
        <>
            <div className="container strategy-wrapper">
                <div className="row">
                    <div className="col-12 mb-5">
                        <div className="star-wrapper" style={{ justifyContent: "center" }}>
                            <img className="star" src={star} alt="Star" />
                            <p>Strategy</p>
                        </div>
                        <h3 className='section-heading'>Crafting Excellence Through Innovation</h3>
                    </div>
                    <div className='strategy-content-wrapper'>
                        <div className='col-lg-3 col-12 strategy-cards'>
                            {strategyDetails.map((card) => (
                                <StrategyCard
                                    key={card.id}
                                    img = {card.img}
                                    title={card.heading}
                                    onClick={() => handleStrategyCardClick(card.id)}
                                    isActive={activeStrategyCard === card.id}
                                />
                            ))}
                        </div>
                        <div className='col-lg-9 col-12 strategy-content'>
                            {activeStrategyCard && (
                                <>
                                    <div className='strategy-detail'>
                                        <h3>{strategyDetails[activeStrategyCard - 1].title}</h3>
                                        <h6>{strategyDetails[activeStrategyCard - 1].subtitle}</h6>
                                        <p>{strategyDetails[activeStrategyCard - 1].detail}</p>
                                    </div>
                                    <h3 style={{cursor:'pointer'}}>{/* Add navigation here */} Explore</h3>
                                    <div className='strategy-icons-wrapper'>
                                        {strategyDetails[activeStrategyCard - 1].icons.map((icon, index) => (
                                            <StrategyIconBox
                                                key={index}
                                                imagePath={icon.path}
                                                title={icon.title}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Strategy