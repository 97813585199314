import React from "react";
import { useLocation } from "react-router-dom";
// import components
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";

const descriptions = [
  "Introducing BizFi, a dynamic fintech company poised to transform the lending landscape. With ambitious goals to overhaul traditional practices, BizFi is dedicated to streamlining and modernizing the lending process.  ",
];
const linkText =
  "Our collaboration has been focused on developing innovative solutions that simplify and enhance the borrowing experience, setting new standards in the industry. ";

function BizifiProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Bizifi"} />
      <Introduction
        src={project.imagePath}
        linkText={linkText}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default BizifiProjectDetail;
