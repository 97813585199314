import React from "react";
import "./Footer.css";

// Assets Import
import logo from "../../../assets/9exbluelogo.webp";

// Components Import
import SocialmediaCard from "./SocialmediaCard";
import { useNavigate } from "react-router-dom";
import { PopupButton } from "@typeform/embed-react";

function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <footer>
        <div className="container-fluid footer-wrapper">
          <div className="row">
            <div
              className="col-lg-3 col-md-3 col-sm-12 col-12"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <a className="logo" href="index.html">
                {" "}
                <img src={logo} alt="logo" width="130" height="60" />
              </a>
              {/* <p style={{fontSize: "1.4rem", fontWeight: "500"}}>Our talented team of engineers and design experts join forces to create amazing web and mobile experiences. We make sure every interaction is top-notch by rigorously checking for bugs with our QA team. We believe in moving fast – our prototypes and MVPs launch quickly, and our single-page websites are like a digital symphony. Beyond just writing code, we're your strategic partners. Our insights into product development and our expert product management team work closely with you, guiding you towards long-term success. Let's team up and build something extraordinary – the possibilities are endless!</p> */}
              <div className="col-lg-12 col-md-12 col-12 footer-section">
                {/* <h3>Pakistan</h3> */}
                <p>Johar Town, Lahore, Pakistan</p>
                <p>The Garage, Riyadh, Saudi Arabia</p>
                <div className="contact-details">
                  <i
                    className="fa-regular fa-envelope"
                    style={{ fontSize: "16px" }}
                  ></i>
                  <p>sales@9exgen.com</p>
                </div>
                <div className="contact-details">
                  <i
                    className="fa-solid fa-phone"
                    style={{ fontSize: "16px" }}
                  ></i>
                  <p>+92 335 4899894</p>
                </div>
                <SocialmediaCard />
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12 col-12 py-sm-5 bordered">
              <div className="container">
                <div className="row gap-5">
                  <div className="col-lg-3 col-md-3 col-12 footer-section">
                    <h3>Company</h3>
                    <ul role="list">
                      <li onClick={() => navigate("/")}>Home</li>
                      <li onClick={() => navigate("/about")}>About Us </li>
                      <li onClick={() => navigate("/career")}>Career</li>
                      <li>
                        <PopupButton
                          id="hjTccK4p"
                          className="quote-btnn"
                          hidden={{ foo: "foo value", bar: "bar value" }}
                        >Contact Us
                        </PopupButton>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12 footer-section">
                    <h3>Services</h3>
                    <ul role="list">
                      <li onClick={() => navigate("/services/mvp-development")}>
                        MVP Development
                      </li>
                      <li onClick={() => navigate("/services/app-development")}>
                        Mobile App Development{" "}
                      </li>
                      <li
                        onClick={() => navigate("/services/digital-marketing")}
                      >
                        Digital Marketing
                      </li>
                      <li
                        onClick={() =>
                          navigate("/services/technology-development")
                        }
                      >
                        Technology Development
                      </li>
                      <li
                        onClick={() => navigate("/services/quality-assurance")}
                      >
                        Quality Assurance
                      </li>
                      <li onClick={() => navigate("/services/UI-UX-design")}>
                        UI-UX Design
                      </li>
                      <li onClick={() => navigate("/services/web-development")}>
                        Web Development
                      </li>
                      <li
                        onClick={() => navigate("/services/startup-experience")}
                      >
                        Startup Experiences
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-3 col-12 footer-section">
                    <h3>Quick Links</h3>
                    <ul>
                      <li onClick={() => navigate("/portfolio")}>Portfolio</li>
                      {/* <li onClick={() => navigate('/about')}>About Us</li> */}
                    </ul>
                    <h3>Insights</h3>
                    <ul>
                      <li onClick={() => navigate("/case-study")}>
                        Case Study
                      </li>
                      <li onClick={() => navigate("/faq")}>FAQ</li>
                      <li onClick={() => navigate("/privacy-policy")}>
                        Privacy Policy
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
