import React from 'react'
import "./Strategy.css"

function StrategyCard({ title, img, onClick, isActive }) {
    return (
        <>
            <div className={`strategy-card ${isActive ? 'active' : ''}`} onClick={onClick}>
                <img src={img} alt="Star" />
                <p>{title}</p>
                <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </div>
        </>
    )
}

export default StrategyCard