import React from "react";

// assests 
import verified from "../../../assets/verify.webp";
import Laptop from "../../../assets/Digit.webp";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import ServiceDescription from "../../Services/Components/ServiceDescription";
import Benefits from "../../Services/Components/Benefits";
import WorkingProcess from "../../Services/Components/WorkingProcess";
import Footprint from "../../Components/Footprint/Footprint";
import Footer from "../../Components/Footer/Footer";

const descriptions = [
  "Our suite of digital marketing solutions is designed to empower businesses like yours to reach, engage, and convert your target audience effectively. With our strategic approach and results-driven mindset, we help you achieve your business objectives and stay ahead in today's competitive market."
];

const contents = [
  {
    title: "Search Engine Optimization (SEO)",
    image: verified,
    detail:"Enhance your online visibility and organic traffic with our SEO services. From keyword research to on-page optimization and link building, we employ industry best practices to improve your website's ranking on search engine results pages (SERPs) and drive qualified leads to your business."
  },
  {
    title: "Pay-Per-Click Advertising (PPC)",
    image: verified,
    detail:"Boost your online presence and generate immediate results with our PPC advertising solutions. Our certified experts craft targeted campaigns on platforms like Google Ads and social media to maximize your ROI, increase conversions, and drive measurable business growth."
  },
  {
    title: "Content Management System (CMS) Integration",
    image: verified,
    detail:"Connect with your audience and build brand loyalty through strategic social media marketing. We develop customized strategies for platforms like Facebook, Instagram, Twitter, and LinkedIn, leveraging engaging content, targeted ads, and community management to foster meaningful relationships with your followers."
  },
  {
    title: "Content Marketing",
    image: verified,
    detail:"Fuel your digital presence with compelling content that resonates with your audience and drives engagement. Our content marketing services encompass content strategy, creation, distribution, and promotion across various channels, including blogs, videos, infographics, and more."
  },
  {
    title: "Email Marketing",
    image: verified,
    detail:"Stay top-of-mind with your audience and nurture leads through personalized email marketing campaigns. From automated workflows to drip campaigns and newsletters, we help you deliver relevant and timely content that drives conversions and strengthens customer relationships."
  },
  {
    title: "Conversion Rate Optimization (CRO)",
    image: verified,
    detail:"Optimize your website and landing pages to maximize conversions and revenue. Through data-driven analysis and A/B testing, we identify areas for improvement in your conversion funnel and implement strategic changes to enhance user experience and drive action."
  },
  {
    title: "Digital Analytics and Reporting",
    image: verified,
    detail:"Gain valuable insights into your digital marketing performance with our analytics and reporting services. We track key metrics, measure campaign effectiveness, and provide actionable insights to inform your marketing strategy and drive continuous improvement."
  },
];
const benefits = [
  {
    title: "Strategic Approach",
    description:
      "We take a tailored approach to digital marketing, developing strategies that align with your unique business goals and objectives.",
  },
  {
    title: "Expertise",
    description:
      "Our team of digital marketing specialists brings years of experience and industry expertise to the table, ensuring the highest quality of service and results.",
  },
  {
    title: "Data-Driven Decisions",
    description:
      "We leverage data and analytics to drive informed decisions and optimize your digital marketing efforts for maximum impact and ROI.",
  },
  {
    title: "Transparent Communication",
    description:
      "We believe in open and honest communication, providing regular updates, insights, and reports to keep you informed and involved every step of the way.",
  },
  {
    title: "Measurable Results:",
    description:
      "Our focus is on delivering measurable results and tangible outcomes that drive business growth and success, ensuring a positive return on your investment.",
  },
];
function Digital() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead
        title="Digital Marketing"
        subtitile="Navigating the digital landscape with precision and purpose. Drive growth, engagement, and conversions with our strategic digital marketing approach."
      />
      <ServiceDescription
        descriptions={descriptions}
        contents={contents}
        mainImage={Laptop}
        heading={"Digital Marketing Solutions"}
      />
      <Benefits benefits={benefits} />
      <WorkingProcess />
      <Footprint />
      <Footer />
    </>
  );
}

export default Digital;
