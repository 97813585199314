import React from "react";
import '../CaseStudyPage.css'
function FeatureCard({ num, title, subtitle }) {
  return (
    <div className="icon-card-feature">
      <h6>{num}</h6>
      <h2>{subtitle}</h2>
    </div>
  );
}

function Feature({title,subtitle,data}) {
  

  return (
    <>
      <div className="container-fluid feature-wrapper">
        <div className="feature-head">
        <p>{title}</p>
        <h1>{subtitle}</h1>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 tech-feature">
            {data.map((item) => (
              <FeatureCard
                key={item.id}
                num={item.num}
                title={item.title}
                subtitle={item.subtitle}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Feature;
