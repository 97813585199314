import React from 'react'
import './Pricings.css'

import { PopupButton } from '@typeform/embed-react'

// Assets Import
import pricing from '../../../../assets/pricing.webp';
import arrow from '../../../../assets/arrow.webp';

function PricingCard() {

    const detailList = [
        {
            title: "Essential features first",
            icon: "check"
        },
        {
            title: "Rapid iterations for feedback",
            icon: "check"
        },
        {
            title: "Market validation",
            icon: "check"
        },
        {
            title: "Cost-effective development",
            icon: "check"
        },
        {
            title: "Agile prioritizations",
            icon: "xmark"
        },
    ]

    return (
        <>
            <div className="col-lg-3 col-md-12 pricing-card">
                <div className="d-flex justify-content-center align-center gap-5">
                    <img src={pricing} alt='mvp development icon' width={50} height={50} />
                    <h4 className='card-heading'>MVP Development</h4>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-end" >
                    <div className='d-flex flex-column justify-content-end align-items-end'>
                        <img className='styled-arrow' src={arrow} alt='arrow' />
                        <h5 className='styled-text'>Starting From Just</h5>
                    </div>
                    <p className='price'>$3000</p>
                </div>
                <p>Fast-track success with MVP development building a streamlined version to validate and iterate swiftly </p>
                <div className='d-flex flex-column gap-4 pb-5'>
                    {detailList.map((list, index) => (
                        <div className='d-flex align-items-center gap-3' key={index}>
                            <i className={`fa-solid fa-circle-${list.icon}`} style={{fontSize: '1.2rem'}}></i>
                            <p style={{fontSize: '1.2rem'}}>{list.title}</p>
                        </div>
                    ))}
                </div>
                <div className="col-12 mx-auto">
                    <PopupButton
                        id="hjTccK4p"
                        className="btn quote-btn"
                        hidden={{ foo: 'foo value', bar: 'bar value' }}
                    >
                        Get a Quote
                    </PopupButton>
                </div>
            </div>
        </>
    )
}

export default PricingCard