import React from "react";
import "./Expertise.css";

// Assets import
import techone from "../../../../assets/php.webp";
import techtwo from "../../../../assets/html.webp";
import techthree from "../../../../assets/javascript.webp";
import techfour from "../../../../assets/android.webp";
import techfive from "../../../../assets/dotnet.webp";
import techsix from "../../../../assets/django.webp";
import techseven from "../../../../assets/fluttercolored.webp";
import techeight from "../../../../assets/reactjs.webp";
import technine from "../../../../assets/python.webp";
import techten from "../../../../assets/flask.webp";
import techeleven from "../../../../assets/css.webp";
import techtwelve from "../../../../assets/laravel.webp";
import techthirteen from "../../../../assets/apple.webp";
import techfourteen from "../../../../assets/aws.webp";

function Expertise() {
  const techIcons = [
    {
      icon: techone,
      width: "120px",
      height: "120px",
    },
    {
      icon: techtwo,
      width: "100px",
      height: "100px",
    },
    {
      icon: techthree,
      width: "80px",
      height: "80px",
    },
    {
      icon: techfour,
      width: "120px",
      height: "120px",
    },
    {
      icon: techfive,
      width: "90px",
      height: "90px",
    },
    {
      icon: techsix,
      width: "60px",
      height: "60px",
    },
    {
      icon: techseven,
      width: "80px",
      height: "80px",
    },
    {
      icon: techeight,
      width: "100px",
      height: "100px",
    },
    {
      icon: technine,
      width: "75px",
      height: "75px",
    },
    {
      icon: techten,
      width: "110px",
      height: "110px",
    },
    {
      icon: techeleven,
      width: "80px",
      height: "80px",
    },
    {
      icon: techtwelve,
      width: "120px",
      height: "120px",
    },
    {
      icon: techthirteen,
      width: "90px",
      height: "90px",
    },
    {
      icon: techfourteen,
      width: "70px",
      height: "70px",
    },
  ];

  return (
    <>
      <div className="container-fluid experty-wrapper">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="experty-header">
              <h2>Our Development Expertise</h2>
              <p>
                At 9exGen IT Solutions, we bring a wealth of expertise in web
                and mobile development, harnessing the latest technologies
                including HTML5, CSS3, JavaScript, PHP, .NET, Python, and more.
                Our dedicated team of developers excels in crafting bespoke
                solutions that not only meet but exceed your business
                objectives. We specialize in creating dynamic web applications,
                responsive websites, and robust mobile apps for both Android and
                iOS. By utilizing industry-leading frameworks such as React,
                Flutter, Django, and Laravel, we deliver exceptional results
                that provide seamless user experiences, boost performance, and
                drive growth.<br/> Whether you’re a startup or an established
                enterprise, partner with us to develop impactful digital
                solutions that stand out in a competitive landscape. Because we
                don’t just build software; we create lasting impressions.{" "}
              </p>
              {/* <button type="submit" className="btn btn-lg expertise-btn">
                                Learn more <i className="fa-solid fa-arrow-right" style={{ fontSize: "2.0rem", color: "#fff", paddingLeft: "10px" }}></i>
                            </button> */}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 expertyIcons-wrapper">
            {techIcons.map((item, index) => (
              <div
                key={index}
                className="experty-card"
                style={{
                  backgroundImage: `url(${item.icon})`,
                  width: item.width,
                  height: item.height,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Expertise;
