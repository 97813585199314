import React, { useState, useEffect } from "react";
import "./Opportunity.css";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../Components/Footer/Footer";
import axios from "axios";
import Question from "./Question";

function Opportunity() {
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true); 
  const token = "0fa2b7cb9d9aa5bff41508a1d1dfb6ff";

  const fetchJobs = () => {
    setLoading(true);
    axios
      .post("https://cca.9exgen.com/api/jobs", {}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setJobs(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const toggleAnswer = (index) => {
    setExpandedIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((i) => i !== index);
      } else {
        return [...prevIndexes, index];
      }
    });
  };

  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead title="Job Positions" />
      <div className="container job-wrap my-5">
        <div className="col-lg-4 col-md-12 col-sm-12 col-12 job-cont">
          <h1>Our Job Openings</h1>
          <h2>Find your perfect role</h2>
        </div>

        <div className="row col-lg-9 col-md-12 col-sm-12 col-12 job-area">
          {loading ? (
            <p>Loading...</p> 
          ) : jobs.length === 0 ? (
            <p>No jobs available</p>
          ) : (
            jobs.map((content, index) => (
              <div key={index} className="job-item">
                <div
                  className="available-job"
                  onClick={() => toggleAnswer(index)}
                >
                  <p>{content.title}</p>
                  <button className="see-job">
                    {expandedIndexes.includes(index)
                      ? "Hide Job Description"
                      : "See Job Description"}
                  </button>
                </div>
                <div
                  className={`answer ${
                    expandedIndexes.includes(index) ? "expanded" : ""
                  }`}
                >
                  <p>{content.desc}</p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <Question/>
      <Footer />
    </>
  );
}

export default Opportunity;
