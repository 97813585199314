import React from 'react'
import './BreadcrumbHead.css'

function BreadcrumbHead({ title,subtitile }) {
    return (
        <>
            <div className="container-fluid breadcrumb-head">
                <h2 style={{ fontSize: " 3.8rem", fontWeight: "800" }}>{title}</h2>
                <p>{subtitile}</p>
            </div>
        </>
    )
}

export default BreadcrumbHead