import React from 'react'
import '../main.css'
import './Services.css'

// Components Import
import WhatsappIcon from '../Components/WhatsappIcon/WhatsappIcon'
import Navbar from '../Components/Navbar/Navbar'
import ServiceHeader from './Components/ServiceHeader'
import Contact from '../Components/Contact/Contact'
import BlogsContainer from '../Blog/Components/BlogsContainer'
import Footprint from '../Components/Footprint/Footprint'
import Footer from '../Components/Footer/Footer'
import ServicesDetailListing from './Components/ServicesDetailListing'

function Services() {
    return (
        <>
            <WhatsappIcon />
            <Navbar />
            <ServiceHeader />
            <ServicesDetailListing />
            <Contact />
            <BlogsContainer />
            <Footprint />
            <Footer />
        </>
    )
}

export default Services