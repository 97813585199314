import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import Footprint from "../../Components/Footprint/Footprint";
import Footer from "../../Components/Footer/Footer";
import lap from "../../../assets/Digit.webp";

function BlogDetails() {
  const token = "0fa2b7cb9d9aa5bff41508a1d1dfb6ff";

  const { slug } = useParams();
  const [blogDetail, setBlogDetail] = useState({});

  const fetchBlogDetails = () => {
    axios
      .get(`https://cca.9exgen.com/api/9ex_blogs/detail/${slug}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setBlogDetail(response.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  useEffect(() => {
    fetchBlogDetails();
  }, []);

  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <div className="blogdetails-header">
        <h2>{blogDetail.title}</h2>
      </div>
      <div className="container blogdetails-wrapper">
        {/* <div className=""> */}
          {/* <div className="col-md-12 col-sm-12 col-12"> */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 blog-content">
            <div className="blog-header">
              <p>{blogDetail.published_date?.split("T")[0]}</p>
              <h3>{blogDetail.title}</h3>
            </div>
            <div className="blog-img">
              <img src={blogDetail.image_url} alt="blog" />
            </div>
            <div className="blog-wrapper">
              <p>{blogDetail.text}</p>
            </div>
            <div className="icons-row">
              <div className="copy-icon">
                <i className="fas fa-link icons"></i>
                <p>Copy Link</p>
              </div>
              <i className="fab fa-facebook-f icon"></i>
              <i className="fab fa-twitter icon"></i>
              <i className="fab fa-instagram icon"></i>
            </div>
          </div>
        {/* </div> */}
        {/* </div> */}
      </div>
      <Footprint />
      <Footer />
    </>
  );
}

export default BlogDetails;
