import React from "react";
// assests 
import verified from "../../../assets/verify.webp";
import Laptop from "../../../assets/qa.webp";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import ServiceDescription from "../../Services/Components/ServiceDescription";
import Benefits from "../../Services/Components/Benefits";
import WorkingProcess from "../../Services/Components/WorkingProcess";
import Footprint from "../../Components/Footprint/Footprint";
import Footer from "../../Components/Footer/Footer";


const descriptions = [
  "Our dedicated team of quality assurance experts is committed to ensuring that your digital products meet the highest standards of quality, reliability, and performance. With our meticulous approach and advanced testing methodologies, we help you deliver flawless experiences to your users and drive business success."
];

const contents = [
  {
    title: "Test Planning and Strategy",
    image: verified,
    detail:"A strong foundation is crucial for effective quality assurance. We work closely with you to develop customized test plans and strategies tailored to your project's unique requirements. From defining test objectives to selecting the appropriate testing methodologies, we ensure that every aspect of your product is thoroughly evaluated."
  },
  {
    title: "Functional Testing",
    image: verified,
    detail:"Ensure that your product meets its intended functionality with our comprehensive functional testing services. Our experienced testers perform rigorous tests to verify that each feature and component of your application performs as expected, identifying and resolving any functional defects before they impact your users."
  },
  {
    title: "Performance Testing",
    image: verified,
    detail:"Optimize your product's performance and scalability with our performance testing services. We simulate real-world usage scenarios to measure your application's responsiveness, stability, and scalability under various load conditions. This helps you identify performance bottlenecks and optimize your system for peak performance."
  },
  {
    title: "Security Testing",
    image: verified,
    detail:"Protect your digital assets and customer data with our security testing services. Our experienced security testers conduct thorough assessments to identify vulnerabilities and security weaknesses in your application. We perform penetration testing, code reviews, and security audits to ensure that your product is secure and resilient against "
  },
  {
    title: "Compatibility Testing",
    image: verified,
    detail:"Ensure seamless user experiences across devices and platforms with our compatibility testing services. We verify that your application functions correctly on different operating systems, browsers, and devices, ensuring compatibility with a wide range of configurations and environments."
  },
  {
    title: "Usability Testing",
    image: verified,
    detail:"Deliver intuitive and user-friendly experiences with our usability testing services. We conduct usability tests with real users to evaluate the usability, accessibility, and overall user experience of your application. This helps you identify usability issues and make informed design decisions to enhance user satisfaction."
  },
  {
    title: "Regression Testing",
    image: verified,
    detail:"Prevent regression defects and ensure the stability of your application with our regression testing services. We perform systematic tests to verify that new code changes or enhancements do not introduce unintended side effects or break existing functionality. This ensures that your product maintains its quality and reliability with each new release."
  },

];
const benefits = [
  {
    title:"Expertise",
    description:"Our team consists of skilled quality assurance professionals with extensive experience in testing various types of digital products and applications."
  },
  {
    title:"Comprehensive Approach",
    description:"We offer a comprehensive suite of quality assurance services covering all aspects of the testing lifecycle, from planning and execution to reporting and analysis."
  },
  {
    title:"Advanced Tools and Methodologies",
    description:"We leverage industry-leading tools and testing methodologies to ensure thorough and efficient testing of your digital products."
  },
  {
    title:"Customization",
    description:"We understand that every project is unique. Our quality assurance solutions are tailored to your specific requirements, ensuring that we address your unique challenges and goals."
  },
  {
    title:"Commitment to Excellence",
    description:" We are committed to delivering excellence in quality assurance, ensuring that your digital products meet the highest standards of quality, reliability, and performance."
  },
  {
    title:"Customer Satisfaction",
    description:"We prioritize customer satisfaction and strive to exceed your expectations with our quality assurance services, providing timely and actionable insights to help you deliver exceptional user experiences."
  },
];
function QualityAsurance() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead
        title="Quality Assurance"
        subtitile="From functionality to security, we ensure excellence in every aspect of your digital presence."
      />
      <ServiceDescription
        descriptions={descriptions}
        contents={contents}
        mainImage={Laptop}
        heading={"Comprehensive Quality Assurance Solutions"}
      />
      <Benefits benefits={benefits} />
      <WorkingProcess />
      <Footprint />
      <Footer />
    </>
  );
}

export default QualityAsurance;
