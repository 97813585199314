import React from "react";
// assests 
import verified from "../../../assets/verify.webp";
import Laptop from "../../../assets/mvp.webp";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import ServiceDescription from "../../Services/Components/ServiceDescription";
import Benefits from "../../Services/Components/Benefits";
import WorkingProcess from "../../Services/Components/WorkingProcess";
import Footprint from "../../Components/Footprint/Footprint";
import Footer from "../../Components/Footer/Footer";

const descriptions = [
 "We specialize in transforming your innovative ideas into reality through our MVP development services. We understand the significance of validating your concept swiftly and efficiently. With our MVP approach, you can test the waters, gather valuable insights, and refine your product iteratively."
];

const contents = [
  {
    title: "Strategic Planning",
    image: verified,
    detail:"We begin by delving deep into your vision and objectives. Our team works closely with you to understand your target market, competition, and unique value proposition. This strategic groundwork forms the blueprint for your MVP development journey."
  },
  {
    title: "Rapid Prototyping",
    image: verified,
    detail:"Time is of the essence when it comes to MVP development. Leveraging agile methodologies, we swiftly craft prototypes of your product, focusing on the core features essential for its functionality. This iterative process ensures quick turnaround times without compromising quality."
  },
  {
    title: "User-Centric Approach",
    image: verified,
    detail:"User feedback is the cornerstone of MVP development. We facilitate user testing and gather insights early in the development cycle to validate assumptions and refine the product based on real-world usage. By putting your users at the center, we ensure your MVP resonates with your target audience."
  },
  {
    title: "Scalability and Flexibility",
    image: verified,
    detail:"While MVPs are minimalistic in nature, they lay the foundation for future growth. Our development approach prioritizes scalability and flexibility, allowing your product to evolve seamlessly as you gather feedback and insights post-launch."
  },
  {
    title: "Continuous Improvement",
    image: verified,
    detail:"Launching your MVP is just the beginning. We're committed to supporting you on your journey to success. Our team monitors key metrics, analyzes user behavior, and collaborates with you to identify opportunities for enhancement and iteration."
  },
];
const benefits = [
  {
    title: "Proven Expertise",
    description:
      "With years of experience in MVP development, we bring a wealth of knowledge and expertise to the table.",
  },
  {
    title: "Agile Methodologies",
    description:
      "Our agile development approach ensures rapid iterations, allowing you to adapt to changing market dynamics swiftly.",
  },
  {
    title: "Transparent Communication",
    description:
      "We believe in open and transparent communication every step of the way, keeping you informed and involved throughout the development process.",
  },
  {
    title: "Cost-Efficiency",
    description:
      "Our focus on essential features and rapid development cycles translates to cost-effective solutions that maximize your ROI.",
  },
];
function MVP() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead
        title="MVP Development"
        subtitile="Transform your vision into reality with our MVP development services. Launch fast, learn quickly, and iterate for success."
      />
      <ServiceDescription
        descriptions={descriptions}
        contents={contents}
        mainImage={Laptop}
        heading="Introducing Our MVP Development Services"
      />
      <Benefits benefits={benefits} />
      <WorkingProcess />
      <Footprint />
      <Footer />
    </>
  );
}

export default MVP;
