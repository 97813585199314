import React from "react";
import star from '../../../assets/Star.webp';

function Benefits({ benefits }) {
    return (
        <div className="container" style={{ marginTop: '30px' }}>
            <div className="col-lg-12 col-12">
                <div className="strategy-detail">
                    <h3>Why Choose Us?</h3>
                    <div>
                    </div>
                    {benefits.map((item, index) => (
                        <div key={index} className="benefit-content">
                            <div className="benefit-header">
                                <img className="star" src={star} alt="Star" />
                                <h1 className="title">{item.title}:</h1>
                            </div>
                            <p className="description">{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Benefits;
