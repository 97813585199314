import React from "react";
import "../Services.css";

// Assets Import
import star from "../../../assets/Star.webp";

function ServiceHeader() {
  return (
    <>
      <div className="container services-header">
        <div className="row">
          <div className="col-lg-9 col-md-12 col-sm-12 col-12">
            <div className="star-wrapper" style={{ justifyContent: "start" }}>
              <img className="star" src={star} alt="Star" />
              <p>Perfect Strategy</p>
            </div>
            <h1>Empowering Visions, Engineering Solutions</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-10 col-md-12 col-sm-12 offset-lg-2">
            <div className="styled-header">
              <h3>Crafting Tomorrow's Success Today</h3>
              <p>
                We go beyond software development – we empower your business
                visions with expert engineering solutions. Our commitment to
                innovation, industry customization, and collaborative
                partnerships ensures that every project is not just a product
                but a strategic success for your enterprise. Join us in shaping
                the future of technology with solutions that transcend
                expectations
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceHeader;
