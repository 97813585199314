import React from "react";
// assests 
import verified from "../../../assets/verify.webp";
import Laptop from "../../../assets/web.webp";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import ServiceDescription from "../../Services/Components/ServiceDescription";
import Benefits from "../../Services/Components/Benefits";
import WorkingProcess from "../../Services/Components/WorkingProcess";
import Footprint from "../../Components/Footprint/Footprint";
import Footer from "../../Components/Footer/Footer";

const descriptions = [
  "We offer a comprehensive range of web development services tailored to meet your business needs. With our expertise in both front-end and back-end development, we ensure your website not only looks great but also functions flawlessly. Our focus on SEO-friendly practices guarantees your online presence stands out in the digital landscape."
];

const contents = [
  {
    title: "Front-end Development",
    image: verified,
    detail:"Our front-end development team crafts visually stunning and user-friendly interfaces that captivate your audience. From layout design to interactive elements, we prioritize aesthetics without compromising functionality."
  },
  {
    title: "Back-end Development",
    image: verified,
    detail:"Behind every great website lies a robust back-end infrastructure. Our team specializes in building scalable, secure, and efficient back-end systems that power your website's functionality seamlessly."

  },
  {
    title: "Content Management System (CMS) Integration",
    image: verified,
    detail:"Take control of your website content effortlessly with our CMS integration services. Whether you prefer WordPress, Drupal, or another CMS platform, we customize solutions that streamline content management, saving you time and effort."

  },
  {
    title: "E-commerce Features",
    image: verified,
    detail:"Unlock the full potential of online sales with our e-commerce solutions. From product listings to secure payment gateways, we empower your website with features that enhance the shopping experience and drive conversions."

  },
  {
    title: "Responsive Design",
    image: verified,
    detail:"In today's multi-device world, responsiveness is key. Our responsive design approach ensures your website adapts seamlessly to various screen sizes and devices, delivering an optimal viewing experience for every visitor."

  },
  {
    title:"SEO Optimization",
    image:verified,
    detail:"Stand out in search engine results with our SEO-friendly web development practices. We implement best practices for on-page optimization, site speed, mobile-friendliness, and more, helping you climb the ranks and attract organic traffic."

  }
];
const benefits = [
  {
    title: "Expertise",
    description:
      "Our team comprises skilled developers with years of experience in web development.",
  },
  {
    title: "Custom Solutions",
    description:
      "We understand that every business is unique, so we tailor our services to match your specific requirements.",
  },
  {
    title: "Timely Delivery",
    description:
      "We prioritize deadlines and ensure timely delivery without compromising quality.",
  },
  {
    title: "Continuous Support",
    description:
      "Our commitment doesn't end with project completion. We provide ongoing support and maintenance to keep your website running smoothly.",
  },
];
function Web() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead
        title="Web Development"
        subtitile="Tailored solutions for a standout digital presence. Elevate your brand's visibility and performance."
      />
      <ServiceDescription
      heading={"Welcome to Our Web Development Services"}
        descriptions={descriptions}
        contents={contents}
        mainImage={Laptop}
      />
      <Benefits benefits={benefits} />
      <WorkingProcess />
      <Footprint />
      <Footer />
    </>
  );
}

export default Web;
