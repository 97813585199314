import React from "react";
// assests 
import verified from "../../../assets/verify.webp";
import Laptop from "../../../assets/TechDev.webp";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import ServiceDescription from "../../Services/Components/ServiceDescription";
import Benefits from "../../Services/Components/Benefits";
import WorkingProcess from "../../Services/Components/WorkingProcess";
import Footprint from "../../Components/Footprint/Footprint";
import Footer from "../../Components/Footer/Footer";

// Components Import

const descriptions = [
 "Our comprehensive services are designed to empower businesses to leverage the latest advancements in technology to solve complex challenges, enhance operations, and stay ahead in today's fast-paced digital landscape."
];

const contents = [
  {
    title: "Custom Software Development",
    image: verified,
    detail:"Elevate your business with our custom software development services. From concept to deployment, our team collaborates closely with you to understand your unique requirements and deliver scalable, user-centric solutions tailored to your specific needs and objectives."
  },
  {
    title: "Cloud Computing Solutions",
    image: verified,
    detail:"Unlock the potential of the cloud with our cloud computing solutions. Whether you're looking to migrate existing infrastructure or develop cloud-native applications, we leverage platforms like AWS, Azure, and Google Cloud to optimize performance, scalability, and cost-efficiency."
  },
  {
    title: "Internet of Things (IoT) Development",
    image: verified,
    detail:"Transform your business processes with our IoT development services. We design and implement IoT solutions that enable seamless connectivity, data-driven insights, and automation, empowering you to optimize operations, enhance customer experiences, and drive innovation."
  },
  {
    title: "Blockchain Development",
    image: verified,
    detail:"Explore the possibilities of blockchain technology with our blockchain development expertise. From decentralized applications (DApps) to smart contracts and custom blockchain solutions, we leverage blockchain's decentralized and immutable nature to revolutionize various industries."
  },
  {
    title: "Artificial Intelligence (AI) and Machine Learning (ML)",
    image: verified,
    detail:"Harness the power of AI and ML to gain actionable insights and automate processes. Our AI and ML solutions encompass predictive analytics, natural language processing, computer vision, and more, enabling you to make data-driven decisions and drive innovation."
  },
  {
    title: "Augmented Reality (AR) and Virtual Reality (VR)",
    image: verified,
    detail:"Immerse your audience in captivating experiences with our AR and VR development services. From interactive training simulations to immersive marketing campaigns, we create immersive AR and VR applications that engage users and drive brand awareness."
  },
  {
    title: "Cybersecurity Solutions",
    image: verified,
    detail:"Protect your business from cyber threats with our cybersecurity solutions. We offer comprehensive services including threat detection, vulnerability assessments, security audits, and compliance, ensuring the confidentiality, integrity, and availability of your data and systems."
  },
];
const benefits = [
  {
    title: "Expertise",
    description:
      "Our team of experienced developers and technologists has a proven track record of delivering innovative and high-quality technology solutions.",
  },
  {
    title: "Customization",
    description:
      "We understand that every business has unique requirements. Our solutions are tailored to address your specific needs and objectives.",
  },
  {
    title: "Cutting-Edge Technologies",
    description:
      "We stay up-to-date with the latest advancements in technology, allowing us to leverage the most relevant tools and techniques to solve your challenges.",
  },
  {
    title: "Collaborative Approach",
    description:
      "We believe in close collaboration with our clients, involving you throughout the development process to ensure the final solution aligns with your vision.",
  },
  {
    title: "Scalability",
    description:
      "Our solutions are designed to scale with your business, allowing you to adapt and grow in today's rapidly evolving digital landscape.",
  },
  {
    title: "Security",
    description:
      "We prioritize the security and integrity of your data and systems, implementing robust security measures to protect against cyber threats and vulnerabilities.",
  },
];
function Technology() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead
        title="Technology Development"
        subtitile="From idea to implementation, we are your partners in digital innovation."
      />
      <ServiceDescription
        descriptions={descriptions}
        contents={contents}
        mainImage={Laptop}
        heading={"Leading the Way in Technology Development"}
      />
      <Benefits benefits={benefits} />
      <WorkingProcess />
      <Footprint />
      <Footer />
    </>
  );
}

export default Technology;
