import axios from "axios";
import React, { useEffect, useState } from "react";
import BlogCard from "./BlogCard";

function BlogsListing() {
  const token = "0fa2b7cb9d9aa5bff41508a1d1dfb6ff";

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState("");

  const fetchBlogs = () => {
    setLoading(true);
    axios
      .get("https://cca.9exgen.com/api/9ex_blogs", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((response) => {
        setBlogs(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <>
      <div className="container bloglisting-wrapper">
        <div className="row">
          <div className="col-12">
            <h3 className="section-heading">Latest News and Articles</h3>
            <p className="section-text">
              We currently support Facebook, Instagram, LinkedIn and Twitter.
              More to come. Felix is purpose built for ease of use and complete
              flexability.
            </p>
          </div>
          <div
            className="row col-lg-12 col-md-12 col-sm-12  col-12 gap-5 my-5 "
            style={{ display: "flex", justifyContent: "center" }}
          >
            {loading ? (
              <h1 style={{ display: "flex", justifyContent: "center" }}>
                Loading...
              </h1>
            ) : blogs.length == 0 ? (
              <p>No Blogs Available</p>
            ) : (
              blogs.map((data, index) => <BlogCard key={index} data={data} />)
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogsListing;
