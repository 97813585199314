import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CaseStudy.css";

// Components Import
import CaseStudyCard from "./CaseStudyCard";

// Assets import
import star from "../../../../assets/WhiteStar.webp";
import studyOne from "../../../../assets/tamwwil.webp";
import studyTwo from "../../../../assets/bizfi.webp";
import studyThree from "../../../../assets/dukandar.webp";
import studyFour from "../../../../assets/kazk.webp";

function CaseStudy() {
  const navigate = useNavigate();

  const caseStudies = [
    {
      title: "Tamwwil",
      detail:
        "The pioneering Algerian platform for simplifying the search for top-tier financing, offering a secure and efficient process for both individuals and businesses. ",
      onClick: "/case-study/tamwwil-project-detail",
      imagePath: studyOne,
      linkUrl: "https://www.tamwwil.com",
    },
    {
      title: "BizFi",
      detail:
        "A dynamic fintech company revolutionizing the lending industry with innovative solutions designed to simplify and modernize the borrowing process.",
      onClick: "/case-study/bizifi-project-detail",
      imagePath: studyTwo,
      linkUrl: "https://bizfi.io",

    },
    {
      title: "Dukandar Online",
      detail:
        "A transformative platform providing SMEs with comprehensive digital tools, including automated inventory, online POS, and data analytics to drive business growth. ",
      onClick: "/case-study/dukandaronline-project-detail",
      imagePath: studyThree,
      appStoreUrl: "",
      googlePlayUrl:
        "https://play.google.com/store/apps/details?id=com.bismtech.dukandaronline&hl=en_US",
    },
    {
      title: "Kazakhstan",
      detail:
        "A modern solution enhancing embassy services for Kazakhstan, ensuring seamless connectivity and efficient service management for a better user experience. ",
      onClick: "/case-study/kazakhstan-project-detail",
      imagePath: studyFour,
      linkUrl: "https://kazakhstanhouse.pk/",

    },
  ];

  const [expandedCardIndex, setExpandedCardIndex] = useState(null);

  const handleCardClick = (index) => {
    setExpandedCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className="container-fluid casestudy-wrapper">
        <div
          className="star-wrapper"
          style={{ justifyContent: "center", paddingTop: "50px" }}
        >
          <img className="star" src={star} alt="Star" />
          <p>Case Study</p>
        </div>
        <h3 className="section-heading">
          Unraveling Success Stories Across Diverse Industries
        </h3>
        <div className="row cards-wrapper">
          {caseStudies.map((project, index) => (
            <CaseStudyCard
              key={index}
              data={project}
              isExpanded={index === expandedCardIndex}
              onCardClick={() => handleCardClick(index)}
              onClick={() => navigate(project.onClick,{state:project})}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default CaseStudy;
