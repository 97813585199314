import React from 'react'
import './Vision.css'
import VisionCard from './VisionCard'

function Vision() {
  return (
    <>
      <div className="container">
         <div className="row">
            <div className="col-sm-12 col-md-4 mb-3">
                <VisionCard Icon={<i className="fa-solid fa-eye"></i>} heading="Our Vision" detail="Bridging the gap between entrepreneurial vision and technical execution, we envision empowering businesses through innovative and impactful software solutions." /> 
            </div>
            <div className="col-sm-12 col-md-4 mb-3">
                <VisionCard Icon={<i className="fa-solid fa-rocket"></i>} heading="Our Mission" detail="Our mission is to lead the way in full-stack software development, crafting impactful, enterprise-grade solutions tailored to diverse industries. We are committed to supporting startups, offering the technical expertise and resources essential for their success." /> 
            </div>
            <div className="col-sm-12 col-md-4 mb-3">
                <VisionCard Icon={<i className="fa-solid fa-gem"></i>} heading="Our Values" detail="We blend entrepreneurial vision with technical expertise, crafting impactful software solutions for diverse industries. Our full-stack development supports startups, ensuring lasting success through innovation." /> 
            </div>
        </div>
    </div>
    </>
  )
}

export default Vision