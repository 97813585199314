import React from "react";
// assests 
import verified from "../../../assets/verify.webp";
import Laptop from "../../../assets/startupSer.webp";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import ServiceDescription from "../../Services/Components/ServiceDescription";
import Benefits from "../../Services/Components/Benefits";
import WorkingProcess from "../../Services/Components/WorkingProcess";
import Footprint from "../../Components/Footprint/Footprint";
import Footer from "../../Components/Footer/Footer";

// Components Import

const descriptions = [
  "We understand the unique challenges and opportunities that startups face, and our comprehensive suite of services is tailored to support you at every stage of your entrepreneurial endeavor. Whether you are just starting out or looking to scale your business, we are here to provide the guidance, resources, and expertise you need to turn your vision into reality."
];

const contents = [
  {
    title: "Ideation and Validation",
    image: verified,
    detail:"Every successful startup begins with a great idea. Our experienced team works closely with you to refine your concept, validate market demand, and assess feasibility. Through market research, competitor analysis, and customer feedback, we help you shape your idea into a viable business opportunity."
  },
  {
    title: "Business Planning and Strategy",
    image: verified,
    detail:"Crafting a solid business plan is essential for laying the foundation of your startup. We assist you in developing a comprehensive business strategy, defining your goals, target market, revenue model, and go-to-market strategy. Our strategic guidance helps you set clear objectives and create a roadmap for success."
  },
  {
    title: "Product Development and Prototyping",
    image: verified,
    detail:"Transform your vision into a tangible product with our product development and prototyping services. Whether you are building a software application, hardware device, or physical product, our team of engineers, designers, and developers collaborates closely with you to bring your ideas to life. From MVP development to rapid prototyping, we help you iterate and refine your product until it's ready for market."
  },
  {
    title: "Branding and Marketing",
    image: verified,
    detail:"Establishing a strong brand identity and creating awareness are crucial steps in building a successful startup. We help you develop your brand identity, logo, messaging, and marketing materials that resonate with your target audience. Our digital marketing experts devise tailored strategies to attract customers, drive engagement, and generate leads through various channels."
  },
  {
    title: " Funding and Investment",
    image: verified,
    detail:"Securing funding is often a critical milestone for startups looking to scale. We provide guidance and support throughout the fundraising process, helping you prepare pitch decks, connect with investors, and negotiate terms. Whether you're seeking seed funding, venture capital, or crowdfunding, we help you navigate the complexities of fundraising and find the right investors for your startup."
  },
  {
    title: "Operations and Growth",
    image: verified,
    detail:"Scaling your startup requires careful planning and execution. We offer operational support and strategic guidance to help you streamline processes, optimize workflows, and scale your team effectively. Our growth strategies focus on customer acquisition, retention, and expansion, helping you achieve sustainable growth and long-term success."
  },
  {
    title: "Mentorship and Networking",
    image: verified,
    detail:"Building a successful startup is not just about the product; it's also about the people behind it. We provide mentorship and networking opportunities to connect you with experienced entrepreneurs, industry experts, and investors who can offer valuable advice, guidance, and support. Our vibrant startup community fosters collaboration, innovation, and growth."
  },
];
const benefits = [
  {
    title: "Experience",
    description:
      "Our team has a wealth of experience working with startups across various industries, providing tailored solutions to meet their unique needs.",
  },
  {
    title: "Comprehensive Support",
    description:
      "We offer a full suite of services to support startups at every stage of their journey, from ideation to growth and beyond.",
  },
  {
    title: "Personalized Guidance",
    description:
      "We provide personalized guidance, support, and mentorship to help you overcome obstacles and achieve your goals.",
  },
  {
    title: "Strategic Partnerships:",
    description:
      "We have established partnerships with industry leaders, investors, and accelerators to provide you with access to resources, funding, and mentorship opportunities.",
  },
  {
    title: "Proven Success",
    description:
      "Our track record of success speaks for itself, with numerous startups achieving significant milestones and successes with our support.",
  },
];
function StartupExp() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead
        title="Start-Up Experience"
        subtitile="Navigate the startup landscape with confidence and clarity."
      />
      <ServiceDescription
        descriptions={descriptions}
        contents={contents}
        mainImage={Laptop}
        heading={"Empowering Startup Experiences"}
      />
      <Benefits benefits={benefits} />
      <WorkingProcess />
      <Footprint />
      <Footer />
    </>
  );
}

export default StartupExp;
