import React from 'react'
import './VideoTestimonial.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/free-mode';

// Assets import
import star from '../../../../assets/Star.webp';
import testimonyOne from '../../../../assets/video-testimony-one.webp'
import testimonyTwo from '../../../../assets/video-testimony-two.webp'
import testimonyThree from '../../../../assets/video-testimony-three.webp'

function VideoTestimonial() {

    const testimonials = [
        {
            title: "Maria M (CEO)",
            bgImage: testimonyOne,
            link: ""
        },
        {
            title: "Jake williams (CTO)",
            bgImage: testimonyTwo,
            link: ""
        },
        {
            title: "Musa Ibrahim (Co-Founder)",
            bgImage: testimonyThree,
            link: ""
        },
        {
            title: "Adrian Bake",
            bgImage: testimonyTwo,
            link: ""
        },
    ];

    return (
        <>
            <div className="container video-testimony-wrapper" style={{ paddingTop: "40px", marginBottom: "50px" }}>
                <div className="row">
                    <div className="col-12 mb-5">
                        <div className="star-wrapper" style={{ justifyContent: "center" }}>
                            <img className="star" src={star} alt="Star" />
                            <p>Video Testimonials</p>
                        </div>
                        <h3 className='section-heading'>Our Wall of Love and Connection</h3>
                    </div>
                </div>
                <Swiper className="mySwiper"
                        freeMode={true}
                        modules={[FreeMode]}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                        }}
                >
                    {testimonials.map((testimonial, index) => (
                        <SwiperSlide
                            key={index}
                            className='video-testimony-card'
                            style={{ backgroundImage: `url(${testimonial.bgImage})` }}
                        >
                            {testimonial.title}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    )
}

export default VideoTestimonial