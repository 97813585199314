import React from "react";
import "./Services.css";

import verified from "../../assets/verify.webp";
import Laptop from "../../assets/laptop.webp";

// Components Import
import WhatsappIcon from "../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../Components/Navbar/Navbar";
import Footprint from "../Components/Footprint/Footprint";
import Footer from "../Components/Footer/Footer";
import BreadcrumbHead from "../Components/BreadcrumbHead/BreadcrumbHead";
import ServiceContent from "./Components/ServiceContent";
import ServiceDescription from "./Components/ServiceDescription";
import WorkingProcess from "./Components/WorkingProcess";
import Benefits from "./Components/Benefits";

const descriptions = [
  "We offer Web Development Services to businesses of all sizes to give them a boost.",
  "Web development involves a range of tasks, including designing the user interface, developing the back-end functionality, and ensuring that the website or web app is responsive and user-friendly.",
  "Web development encompasses both the visual aspects of a website and its underlying technical infrastructure.",
];

const contents = [
  {
    title: "Front-end Development",
    image: verified,
  },
  {
    title: "Back-end Development",
    image: verified,
  },
  {
    title: "Content Management System (CMS) Integration",
    image: verified,
  },
  {
    title: "E-commerce Features",
    image: verified,
  },
  {
    title: "Responsive Design",
    image: verified,
  },
];
const benefits = [
  {
    title: "Tailored to Specific Needs",
    description:
      "Custom software is designed to address your organization's unique requirements and challenges, ensuring a perfect fit for your operations.",
  },
  {
    title: "Enhanced Efficiency",
    description:
      "Custom solutions streamline processes, automate tasks, and eliminate inefficiencies, boosting productivity and reducing manual work.",
  },
  {
    title: "Scalability",
    description:
      "As your business grows, custom software can easily adapt and scale to accommodate increased data, users, and functionality.",
  },
  {
    title: "Cost Savings",
    description:
      "While the initial investment may be higher, custom software often leads to long-term cost savings by reducing the need for multiple third-party applications and licenses.",
  },
  {
    title: "Competitive Advantage",
    description:
      "Custom software can provide a competitive edge by enabling innovation, quicker response to market changes, and differentiation from competitors.",
  },
  {
    title: "Data Security",
    description:
      "You have control over security measures, ensuring that sensitive data is protected according to your organization's standards and compliance requirements.",
  },
  {
    title: "Integration",
    description:
      "Custom software can be seamlessly integrated with existing systems and applications, optimizing data flow and reducing data silos.",
  },
  {
    title: "Maintenance and Support",
    description:
      "You have the flexibility to choose ongoing maintenance and support options, ensuring that your software remains up-to-date and functional.",
  },
];
function ServicesDetail() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead
        title="Web Development"
        subtitile="Encompasses building and maintaining websites, including front-end (user interface) and back-end (server-side) development."
      />
      <ServiceDescription
        descriptions={descriptions}
        contents={contents}
        mainImage={Laptop}
      />
      <Benefits benefits={benefits} />
      <WorkingProcess />
      <Footprint />
      <Footer />
    </>
  );
}

export default ServicesDetail;
