import React from 'react'
import { useNavigate } from 'react-router-dom';

function BlogCard({ data }) {

    const navigate  = useNavigate()

    return (
        <>
            <div className="row col-lg-6 col-md-6 col-sm-12 col-12  blog-card">
                <img src={data.image_url} alt="Profile" />
                <div className="card-text" style= {{justifyContent: "space-between"}}>
                    <p>{data.title}</p>
                    <p>{data.published_date.split("T")[0]}</p>
                </div>
                <button type="submit" className="btn btn-lg card-btn" onClick={() => navigate(`/blog/${data.slug}`)}>View More</button>
            </div>
        </>
    )
}

export default BlogCard