import React from 'react'
import '../CaseStudyPage.css'

import { PopupButton } from '@typeform/embed-react'

// Assets import
import star from '../../../../assets/Star.webp';
import story from '../../../../assets/challenge.webp';

function Challange({challange}) {
  return (
    <>
        <div className="container intro-wrapper my-5">
            <div className="row intro-head">
               
               
                <div className="col-lg-5 col-md-12 col-sm-12 col-12 intro-content">
                    <h1>The Challenge</h1>
                    {challange.map((desc, index) => (
              <p className="intro-text" key={index}>
                {desc}
              </p>
            ))}
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 my-3 intro-banner">
                    <img src={story} alt="9exGen" />
                </div>

            </div>
        </div>
    </>
  )
}

export default Challange