import React from 'react'

function SocialmediaCard() {
    return (
        <>
            <div className='socialcard'>
                <h5>Follow us on our Social Media</h5>
                <div className='socialicons'>
                    <a href='https://www.facebook.com/9exGen?mibextid=opq0tG'><i className="fa-brands fa-facebook-f" style={{backgroundColor: "#316FF6" }}></i></a>
                    {/* <a href='/'><i className="fa-brands fa-x-twitter" style={{backgroundColor: "#000" }}></i></a>
                    <a href='/'><i className="fa-brands fa-instagram" style={{backgroundColor: "#d62976" }}></i></a> */}
                    <a href='https://www.linkedin.com/company/9exgen-solutions/'><i className="fa-brands fa-linkedin-in" style={{backgroundColor: "#0077b5" }}></i></a>
                </div>
            </div>
        </>
    )
}

export default SocialmediaCard