import React from "react";
import { useLocation } from "react-router-dom";
// import components
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";

const descriptions = [
  "A leader in sugar-free products, No Sugar Company specializes in keto bars and other treats made with high-quality ingredients and no added sugars or fillers. The company is dedicated to providing healthy, delicious alternatives for those pursuing a sugar-free lifestyle. No Sugar Company’s products are crafted to support well-being without compromising on taste.",
];
const linkText =
  "Embrace a healthier lifestyle with No Sugar Company, where we’re dedicated to supporting your dietary goals with clean, wholesome products.";

function NoSugarProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"The No Sugar"} />
      <Introduction
        src={project.imagePath}
        linkText={linkText}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default NoSugarProjectDetail;
