import React, { useState } from "react";
import "./Testimonial.css";

// Assets import
import studyOne from "../../../assets/video-testimony-one.webp";
import studyTwo from "../../../assets/video-testimony-two.webp";
// components import
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import ContactUs from "./ContactUs";



const Testimon = ({ name, title, quote, image }) => {
    return (
        <div className="row testimonial">
            <img src={image} alt={`${name}'s picture`} className="testimonial-image" />
            <div className="testimonial-content">
                <h3 className="testimonial-name">{name}</h3>
                <p className="testimonial-title">{title}</p>
                <p className="testimonial-quote">"{quote}"</p>
            </div>
        </div>
    );
};
  function BreadcrumbHead({ title,subtitile }) {
    return (
        <>
            <div className="container-fluid breadcrumb-head">
                <h2 style={{ fontSize: " 3.8rem", fontWeight: "800" }}>{title}</h2>
                <p>{subtitile}</p>
            </div>
        </>
    )
}
  

function Testimonial () {
  const testimonials = [
    {
        name: 'Maria M',
        title: 'CEO at Company',
        quote: 'This product is amazing! It has completely changed the way we do business.',
        image: studyOne
    },
    {
        name: 'Jake Williams',
        title: 'CTO',
        quote: 'A wonderful experience from start to finish. Highly recommend!',
        image: studyTwo
    },
];
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Testmonial"}/>
      <div className="container portfolio-sec">
        <div className="col-12 portfolio-head" >
          <h3 className="sec-heading">WHAT DO OUR CLIENTS SAY ?</h3>
        </div>
        <div className="testimonial-list">
            {/* <h2 className="testimonial-list-title">What Our Customers Say</h2> */}
            {testimonials.map((testimonial, index) => (
                <Testimon
                    key={index}
                    name={testimonial.name}
                    title={testimonial.title}
                    quote={testimonial.quote}
                    image={testimonial.image}
                />
            ))}
        </div>
      </div>
      <ContactUs/>
      <Footer />
    </>
  );
}

export default Testimonial;
