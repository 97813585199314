import React from "react";

function IconCard({ num, title, subtitle }) {
  return (
    <div className="icon-card">
      <h6>{num}</h6>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </div>
  );
}

function WorkingProcess() {
  const data = [
    {
      id: 1,
      num: "01",
      title: "Analysis and Planning",
      subtitle:
        "The process begins by thoroughly understanding the client's  objectives.",
    },
    {
      id: 2,
      num: "02",
      title: "Analysis and Planning",
      subtitle:
        "The process begins by thoroughly understanding the client's  objectives.",
    },
    {
      id: 3,
      num: "03",
      title: "Analysis and Planning",
      subtitle:
        "The process begins by thoroughly understanding the client's  objectives.",
    },
    {
      id: 4,
      num: "04",
      title: "Analysis and Planning",
      subtitle:
        "The process begins by thoroughly understanding the client's  objectives.",
    },
  ];

  return (
    <>
      <div className="container-fluid expertise-wrapper">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="expertise-header">
              <h2>Working process</h2>
              <p>
                Our IT consulting process is a systematic journey comprising
                four stages: Assessment and Analysis, Planning, Implementation
                and Execution, and Monitoring and Optimization.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 techIcons-wrapper">
            {data.map((item) => (
              <IconCard
                key={item.id}
                num={item.num}
                title={item.title}
                subtitle={item.subtitle}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkingProcess;
