import React from "react";
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";
import { useLocation } from "react-router-dom";

const descriptions = [
  "A specialized app designed to streamline the process of booking Hijama (cupping therapy) appointments. HiCure provides a user-friendly platform where users can easily schedule appointments with certified practitioners. The app includes login credentials for doctors, allowing them to manage their schedules, view appointments, and keep track of patient lists efficiently. It enhances the accessibility and organization of Hijama services, making it convenient for both practitioners and patients to connect and manage their therapeutic sessions.",
];

function HiCureProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"HiCure"} />
      <Introduction
        src={project.imagePath}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default HiCureProjectDetail;
