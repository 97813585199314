import React, { useState } from "react";
import "./Career.css";

function CardDetail({ data }) {
  return (
    <div
      className={`col-lg-5 col-md-5 col-sm-12 col-12 career-card`}
    >
      <div className="career-card-content">
        <h3>{data.title}</h3>
        <p>{data.detail}</p>
      </div>
    </div>
  );
}

export default CardDetail;
