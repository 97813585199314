import React from "react";
import { useNavigate } from "react-router-dom";
import "./Portfolio.css";
//import components
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import Partner from "./Partner";
import Footprint from "../../Components/Footprint/Footprint";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
// Assets import
import logo from "../../../assets/CCAlogo.webp";
import logo1 from "../../../assets/firstpasslogo.webp";
import logo2 from "../../../assets/hicurelogoblack.webp";
import logo3 from "../../../assets/petlogo.webp";
import logo4 from "../../../assets/kitchenaralogo.webp";
import logo5 from "../../../assets/Bizfilogo.webp";
import logo6 from "../../../assets/splash-logo.webp";
import logo7 from "../../../assets/builtoplogoblack.webp";

import cca from "../../../assets/cca-mobileapp.webp";
import Travelet from "../../../assets/Travel.webp";
import Tamwil from "../../../assets/Tamwil.webp";
import bizifi from "../../../assets/bizfi.webp";
import HiCure from "../../../assets/HiCure.webp";
import Dukan from "../../../assets/Dukan.webp";
import NoSugar from "../../../assets/NoSugar.webp";
import Pet from "../../../assets/PetOasis.webp";
import kitchenara from "../../../assets/kitchenara.webp";
import firstpassweb from "../../../assets/firstpassweb.webp";
import Yusra3 from "../../../assets/Yusra3.webp";
import BuiltopMobile from "../../../assets/BuiltopMobileApp.webp";
import Superloyall from "../../../assets/superloyal.webp";
import kazk from "../../../assets/kazk.webp";

function CaseStudyCardDetail({ data, onClick }) {
  return (
    <div className={`container portfolio-card`}>
      <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="col-lg-5 col-md-5 col-sm-12 col-12 head-img">
          <img src={data.Image} alt="Service Banner" />
        </div>
        <div className="col-lg-7 col-md-7 col-sm-12 col-12 portfolio-detail">
          <h3>{data.title}</h3>
          <p>{data.detail}</p>
          <p>{data.detail2}</p>
          <p>{data.detail3}</p>
          <button
            onClick={onClick}
            type="submit"
            className="portfolio-btn"
            style={{ backgroundColor: "#04153A", color: "white" }}
          >
            Case Study
          </button>
        </div>
      </div>
    </div>
  );
}

function Portfolio() {
  const navigate = useNavigate();
  const caseStudies = [
    {
      title: "Cross Culture Asia",
      detail:
        "The leading global association dedicated to supporting overseas Asian women by fostering connections and promoting cultural heritage. Cross Culture Asia provides a platform for networking, community engagement, and business support, enhancing the professional and personal lives of its members. CCA is committed to empowering women and celebrating cultural diversity.",
      link: "",
      Image: cca,
    },
    {
      title: "Dukandar Online",
      detail:
        "A transformative digital platform for SMEs, offering a comprehensive suite of tools including automated inventory management, online POS systems, and data analytics. Dukandar Online helps small and medium-sized businesses drive growth and efficiency with cutting-edge technology. The platform supports businesses in optimizing their operations and enhancing their digital capabilities.",
      link: "",
      Image: Dukan,
    },
    {
      title: "Yusra",
      detail:
        "A comprehensive website offering a diverse range of high-quality services and products, committed to delivering exceptional customer satisfaction. Yusra is designed to cater to various needs within its industry, providing a seamless user experience and maintaining a strong focus on excellence and reliability. The platform stands out for its commitment to quality and customer-centric approach.",
      link: "",
      Image: Yusra3,
    },
    {
      title: "NoSugar",
      detail:
        "A leader in sugar-free products, No Sugar Company specializes in keto bars and other treats made with high-quality ingredients and no added sugars or fillers. The company is dedicated to providing healthy, delicious alternatives for those pursuing a sugar-free lifestyle. No Sugar Company’s products are crafted to support well-being without compromising on taste.",
      link: "",
      Image: NoSugar,
    },
    {
      title: "Kitchenara",
      detail:
        "The leading global association dedicated to supporting overseas Asian women by fostering connections and promoting cultural heritage. Cross Culture Asia provides a platform for networking, community engagement, and business support, enhancing the professional and personal lives of its members. CCA is committed to empowering women and celebrating cultural diversity.",
      link: "",
      Image: kitchenara,
    },
    {
      title: "Travallet",
      detail:
        "An advanced multi-currency digital wallet designed to facilitate global transactions with ease. Travallet offers users seamless access to local markets and currency management while traveling. The platform streamlines financial interactions, ensuring a smooth and efficient experience for users navigating different currencies and financial systems around the world.",
      link: "",
      Image: Travelet,
    },
    {
      title: "TAMWWIL",
      detail:
        "The pioneering Algerian platform for finding top-tier financing solutions, TAMWWIL simplifies the process with a secure and efficient approach for both individuals and businesses. The platform offers streamlined access to various financing options, helping users navigate their financial needs with ease. TAMWWIL is designed to support financial growth and stability through user-friendly and reliable services.",
      link: "",
      Image: Tamwil,
    },
    // {
    //   title:"NUiT",
    //   detail:"An innovative app that provides value-driven data through Cosmic Connection insights in romance, magnetism, business, and friendship. NUiT transcends the superficiality of traditional dating apps, offering a profound, insightful experience to deepen connections and foster meaningful relationships by enhancing self-awareness and understanding of others.",
    //   link: "",
    //   Image:kazk
    // },
    {
      title: "HiCURE",
      detail:
        "A specialized app designed to streamline the process of booking Hijama (cupping therapy) appointments. HiCure provides a user-friendly platform where users can easily schedule appointments with certified practitioners. The app includes login credentials for doctors, allowing them to manage their schedules, view appointments, and keep track of patient lists efficiently. It enhances the accessibility and organization of Hijama services, making it convenient for both practitioners and patients to connect and manage their therapeutic sessions.",
      link: "",
      Image: HiCure,
    },
    {
      title: "PetOasis",
      detail:
        " A dedicated platform for pet care that provides a vast selection of products and services aimed at enhancing the well-being and happiness of pets. 3nDey PetOasis offers everything from health and wellness essentials to engaging toys and grooming supplies, ensuring pet owners have access to high-quality care solutions. The platform is designed to support pet health and enrichment through a user-friendly experience and comprehensive product range.",
      link: "",
      Image: Pet,
    },
    {
      title: "Builtop",
      detail:
        "An all-in-one solution for construction and building management, BuilTop delivers essential tools and resources for effective project planning and execution. The platform facilitates streamlined project management, from initial planning stages through to successful completion. By providing comprehensive support and organizational tools, BuilTop ensures efficient and successful building projects.",
      link: "",
      Image: BuiltopMobile,
    },
    {
      title: "SuperLoyal",
      detail:
        "A sophisticated platform designed to enhance customer engagement through a customizable loyalty program. Superloyal offers businesses the ability to create personalized rewards and tailored promotional offers, fostering stronger connections with their customers. By leveraging data and personalization, Superloyal helps businesses boost customer retention and satisfaction with innovative loyalty solutions.",
      link: "",
      Image: Superloyall,
    },
    {
      title: "BizFi",
      detail:
        "A trailblazer in the fintech sector, BizFi is transforming the lending landscape with its state-of-the-art solutions. The platform redefines borrowing by integrating innovative technology and data-driven insights to create a more streamlined, transparent, and efficient process. BizFi offers a range of tailored lending options designed to meet the unique needs of individuals and businesses, simplifying financial transactions and enhancing accessibility. With a focus on modernizing credit access and optimizing user experience, BizFi is at the forefront of financial innovation and inclusion.",
      link: "",
      Image: bizifi,
    },
    {
      title: "FirstPass-AI",
      detail:
        "A state-of-the-art AI platform engineered to streamline the tech hiring process, focusing on enhancing efficiency and innovation in recruitment. FirstPassAI utilizes advanced algorithms and data-driven insights to simplify candidate selection, reduce hiring times, and match top talent with the right opportunities. The platform revolutionizes recruitment by providing a smarter, more effective approach to finding and securing tech talent.",
      link: "",
      Image: firstpassweb,
    },
    {
      title: "Kazakhstan",
      detail:
        "A modern solution designed to enhance embassy services for Kazakhstan by providing seamless connectivity and efficient service management. The app improves user experience with streamlined access to essential services and information. Kazakhstan App ensures that interactions with the embassy are convenient, effective, and well-organized, supporting a better service experience for users.",
      link: "",
      Image: kazk,
    },
  ];
  const partnerLogos = [logo, logo1, logo2, logo3, logo4, logo5, logo6, logo7];
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Portfolio"} />
      <div className="container portfolio-sec">
        <div className="col-12 portfolio-head">
          <h3 className="sec-heading">Project Spotlight</h3>
          <div className="col-lg-9 col-md-9 col-sm-10 col-12 logo-container">
            {partnerLogos.map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt={`Partner Logo ${index + 1}`}
                className="partner-logo"
              />
            ))}
          </div>
        </div>
        <div className="col-12 portfolio-head">
          <h3 className="sec-heading">Journey Through Our Success Stories</h3>
          <p className="sec-text">
            Explore our exceptional portfolio to witness our transformative work
            in action, encompassing groundbreaking web and mobile applications,
            bespoke software development, and captivating digital experiences
            that push the boundaries of technology and drive business success.
          </p>
        </div>
        <div
          className="row gap-5 mb-5"
          style={{
            display: "flex",
            marginTop: "20px",
          }}
        >
          {caseStudies.map((study, index) => (
            <CaseStudyCardDetail
              key={index}
              data={study}
              onClick={() => navigate(study.link)}
            />
          ))}
        </div>
      </div>
      <Partner />
      <Footprint />
      <Footer />
    </>
  );
}

export default Portfolio;
