import React, { useState } from 'react';
import './CaseStudy.css';

function CaseStudyCard({ data,onClick }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleCardHover = () => {
        setIsExpanded(true);
    };

    const handleCardLeave = () => {
        setIsExpanded(false);
    };

    return (
        <div
            className={`col-lg-2 col-md-3 col-sm-4 col-6 casestudy-card ${isExpanded ? 'expanded' : ''}`}
            style={{backgroundImage: `url('${data.imagePath}')`}}
            onMouseEnter={handleCardHover}
            onMouseLeave={handleCardLeave}
            onClick={onClick}
        >
            <div className='casestudy-card-content'>
                <h3>{data.title}</h3>
                <p>{data.detail}</p>
                {isExpanded && (
                    <div className="arrow-icon">
                        <i className="fa-solid fa-arrow-up-right-from-square" style={{ fontSize: '22px', color: '#fff' }}></i>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CaseStudyCard;
