import React, { useState } from "react";
import "./Portfolio.css";
import { useNavigate } from "react-router-dom";

// Components Import
import PortfolioCard from "./PortfolioCard";

// Assets Import
import star from "../../../../assets/WhiteStar.webp";
import cca from "../../../../assets/cca-mobileapp.webp";
import Travelet from "../../../../assets/Travel.webp";
import Tamwil from "../../../../assets/Tamwil.webp";
import biziDash from "../../../../assets/biziDash.webp";
import bizifi from "../../../../assets/bizfi.webp";
import HiCure from "../../../../assets/HiCure.webp";
import Dukan from "../../../../assets/Dukan.webp";
import NoSugar from "../../../../assets/NoSugar.webp";
import Pet from "../../../../assets/PetOasis.webp";
import kitchenara from "../../../../assets/kitchenara.webp";
import kitchenaraweb from "../../../../assets/kitchenaraweb.webp";
import tamwil2 from "../../../../assets/tamwil2.webp";
import petweb from "../../../../assets/petweb.webp";
import Yusra from "../../../../assets/Yusra.webp";
import firstpassweb from "../../../../assets/firstpassweb.webp";
import Yusra3 from "../../../../assets/Yusra3.webp";
import tamwilDash from "../../../../assets/tamwwilDash.webp";
import firstpassdash from "../../../../assets/firstpassdash.webp";
import BuiltopMobile from "../../../../assets/BuiltopMobileApp.webp";
import BuiltopWeb from "../../../../assets/BuiltopWeb.webp";
import Superloyall from "../../../../assets/superloyal.webp";
import kazk from "../../../../assets/kazk.webp";




function Portfolio() {
  const navigate = useNavigate();
  const allProjects = [
    {
      category: "Mobile Application",
      projects: [
        {
          id: 1,
          imagePath: cca,
          flipTitle: "Cross Culture Asia",
          onClick: "/case-study/cca-project-detail",
          appStoreUrl:
            "https://apps.apple.com/us/app/cross-culture-asia/id6477445988",
          googlePlayUrl:
            "https://play.google.com/store/apps/details?id=com.app.cca&hl=en_US",
        },
        {
          id: 2,
          imagePath: Dukan,
          flipTitle: "Dukandar Online",
          onClick: "/case-study/dukandaronline-project-detail",
          appStoreUrl: "",
          googlePlayUrl:
            "https://play.google.com/store/apps/details?id=com.bismtech.dukandaronline&hl=en_US",
        },
        {
          id: 3,
          imagePath: Yusra3,
          flipTitle: "Yusra",
          onClick: "/case-study/yusra-project-detail",
          appStoreUrl: "",
          googlePlayUrl:
            "https://play.google.com/store/apps/details?id=com.yusra.app&hl=en_US",
        },
        {
          id: 4,
          imagePath: NoSugar,
          flipTitle: "NoSugar",
          onClick: "/case-study/the-nosugar-project-detail",
          appStoreUrl: "",
          googlePlayUrl:
            "https://play.google.com/store/apps/details?id=com.nosugar&hl=en",
        },
        {
          id:5,
          imagePath: kitchenara,
          flipTitle: "Kitchenara",
          onClick: "/case-study/kitchenara-project-detail",
          appStoreUrl: "https://apps.apple.com/sa/app/kitchenara/id6476572788",
          googlePlayUrl:
            " https://play.google.com/store/apps/details?id=com.kitchenaraUser&hl=en ",
        },
        {
          id: 6,
          imagePath: Travelet,
          flipTitle: "Travallet",
          onClick: "/case-study/travellet-project-detail",
          appStoreUrl: "",
          googlePlayUrl: "",
        },
        {
          id: 7,
          imagePath: Tamwil,
          flipTitle: "TAMWWIL",
          onClick: "/case-study/tamwwil-project-detail",
          appStoreUrl: "",
          googlePlayUrl: "",
        },
       
        {
          id: 8,
          imagePath: HiCure,
          flipTitle: "HiCURE",
          onClick: "/case-study/hicure-project-detail",
          appStoreUrl: "",
          googlePlayUrl: "",
        },
        
      
        {
          id: 9,
          imagePath: Pet,
          flipTitle: "PetOasis",
          onClick: "/case-study/petoasis-project-detail",
          appStoreUrl: "",
          googlePlayUrl: "",
        },
       
        {
          id: 10,
          imagePath: BuiltopMobile,
          flipTitle: "Builtop",
          onClick: "/case-study/builtop-project-detail",
          appStoreUrl: "",
          googlePlayUrl: "",
        },
      ],
    },
    {
      category: "Website Development",
      projects: [
        // { id: 9, imagePath: Scienst, flipTitle: "SciNest",onClick: '/case-study/the-nosugar-project-detail',linkUrl: 'https://www.tamwwil.com' },
        {
          id: 11,
          imagePath: tamwil2,
          flipTitle: "TAMWWIL",
          onClick: "/case-study/tamwwil-project-detail",
          linkUrl: "https://www.tamwwil.com",
        },
        {
          id: 12,
          imagePath: Superloyall,
          flipTitle: "SuperLoyal",
          onClick: "/case-study/superloyal-project-detail",
          linkUrl: "https://www.superloyal.com",
        },
        {
          id: 13,
          imagePath: Yusra,
          flipTitle: "Yusra",
          onClick: "/case-study/yusra-project-detail",
          linkUrl: "https://www.yusra.app",
        },
        {
          id: 14,
          imagePath: bizifi,
          flipTitle: "BizFi",
          onClick: "/case-study/bizifi-project-detail",
          linkUrl: "https://bizfi.io",
        },
        {
          id: 15,
          imagePath: kitchenaraweb,
          flipTitle: "Kitchenara",
          onClick: "/case-study/kitchenara-project-detail",
          linkUrl: "https://kitchenara.com",
        },
        {
          id: 16,
          imagePath: BuiltopWeb,
          flipTitle: "Builtop",
          onClick: "/case-study/builtop-project-detail",
          linkUrl: "https://builtop.com",
        },
        {
          id: 17,
          imagePath: petweb,
          flipTitle: "PetOasis",
          onClick: "/case-study/petoasis-project-detail",
          linkUrl: "https://mp-staging.petoasisksa.com/",
        },
        {
          id: 18,
          imagePath: firstpassweb,
          flipTitle: "FirstPass-AI",
          onClick: "/case-study/firstpassai-project-detail",
          linkUrl: "https://firstpassai.com",
        },
        {
          id: 19,
          imagePath: kazk,
          flipTitle: "Kazakhstan",
          onClick: "/case-study/kazakhstan-project-detail",
          linkUrl: "https://kazakhstanhouse.pk/",
        },
      ],
    },
    {
      category: "Dashboards",
      projects: [
        // { id: 12, imagePath: kaz, flipTitle: "Kazakhstan " },
        {
          id: 20,
          imagePath: biziDash,
          flipTitle: "BizFi",
          onClick: "/case-study/bizifi-project-detail",
          linkUrl: "https://bizfi.io",
        },
        {
          id: 21,
          imagePath: tamwilDash,
          flipTitle: "TAMWWIL",
          onClick: "/case-study/tamwwil-project-detail",
          linkUrl: "https://www.tamwwil.com",
        },
        // { id: 15, imagePath: ScinestDash, flipTitle: "SciNest" },
        {
          id: 22,
          imagePath: firstpassdash,
          flipTitle: "FirstPass-AI",
          onClick: "/case-study/firstpassai-project-detail",
          linkUrl: "https://firstpassai.com",
        },
      ],
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState("All Projects");

  const selectedProjects =
    selectedCategory === "All Projects"
      ? allProjects.flatMap((category) => category.projects)
      : allProjects.find((category) => category.category === selectedCategory)
          ?.projects || [];

  return (
    <>
      <div className="container-fluid portfolio-wrapper">
        <div className="row">
          <div className="col-12">
            <div className="star-wrapper d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <div className="portfolio-head d-flex align-items-center gap-3">
                  <img className="star" src={star} alt="Star" />
                  <h4>Portfolio</h4>
                </div>
                <h3 className="section-heading">Our recent dedicated work</h3>
              </div>
              {/* <button type="submit" className="btn btn-lg portfolio-btn">
                See All Portfolio
              </button> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 portfolio-list">
            <h4
              onClick={() => setSelectedCategory("All Projects")}
              className={
                selectedCategory === "All Projects" ? "selected-category" : ""
              }
            >
              All Projects
            </h4>
            {allProjects.map((category) => (
              <h4
                key={category.category}
                onClick={() => setSelectedCategory(category.category)}
                className={
                  selectedCategory === category.category
                    ? "selected-category"
                    : ""
                }
              >
                {category.category}
              </h4>
            ))}
          </div>
          <div className="col-lg-9 col-md-8 col-sm-6 portfolio-data">
            <div className="row gap-0">
              {selectedProjects.map((project) => (
                <div
                  key={project.id}
                  className="col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4 "
                >
                  <PortfolioCard
                    imagePath={project.imagePath}
                    flipTitle={project.flipTitle}
                    flipDetail="Project Detail"
                    onClick={() =>
                      navigate(project.onClick, { state: project })
                    }
    z             />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Portfolio;
