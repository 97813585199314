import React from "react";
import "./CaseStudyPage.css";

// Assets import
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
import studyOne from "../../../assets/tamwwil.webp";
import studyTwo from "../../../assets/bizfi.webp";
import studyThree from "../../../assets/dukandar.webp";
import studyFour from "../../../assets/kazk.webp";
import CaseStudyCardDetail from "./CaseStudyCardDetail";
import { useNavigate } from "react-router-dom";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";

function CaseStudyPage() {
  const navigate = useNavigate();
  const caseStudies = [
    {
      title: "Tamwwil",
      detail:
        "The pioneering Algerian platform for simplifying the search for top-tier financing, offering a secure and efficient process for both individuals and businesses. ",
      link: "",
      Image: studyOne,
    },
    {
      title: "Kazakhstan",
      detail: "A modern solution enhancing embassy services for Kazakhstan, ensuring seamless connectivity and efficient service management for a better user experience. ",
      link: "",
      Image: studyFour,
    },
    {
      title: "BizFi",
      detail:
        "A dynamic fintech company revolutionizing the lending industry with innovative solutions designed to simplify and modernize the borrowing process.",
      link: "",
      Image: studyTwo,
    },
    {
      title: "Dukandar Online",
      detail:
        "A transformative platform providing SMEs with comprehensive digital tools, including automated inventory, online POS, and data analytics to drive business growth. ",
      link: "",
      Image: studyThree,
    },
  
  ];
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"Case Study"}/>
      <div className="container serviceslisting-wrapper">
        <div className="row col-12">
          <h3 className="section-heading">Case Studies</h3>
          <p className="section-text">
            Explore some of the transformative journeys of 9exGen-Solution
            clients.
          </p>
        </div>
        <div
          className="row gap-5 mb-5"
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
          }}
        >
          {caseStudies.map((study, index) => (
            <CaseStudyCardDetail key={index} data={study} onClick={() => navigate(study.link)}/>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CaseStudyPage;
