import React from "react";
// assests 
import verified from "../../../assets/verify.webp";
import Laptop from "../../../assets/app.webp";
// Components Import
import WhatsappIcon from "../../Components/WhatsappIcon/WhatsappIcon";
import Navbar from "../../Components/Navbar/Navbar";
import BreadcrumbHead from "../../Components/BreadcrumbHead/BreadcrumbHead";
import ServiceDescription from "../../Services/Components/ServiceDescription";
import Benefits from "../../Services/Components/Benefits";
import WorkingProcess from "../../Services/Components/WorkingProcess";
import Footprint from "../../Components/Footprint/Footprint";
import Footer from "../../Components/Footer/Footer";


const descriptions = [
  "Our expertise spans across various platforms and technologies, ensuring we deliver high-quality, user-centric apps that meet your business objectives. From initial concept to deployment and maintenance, we provide end-to-end solutions tailored to your unique needs.",
];

const contents = [
  {
    title: "Custom Mobile App Development",
    image: verified,
    detail:"We specialize in creating custom mobile applications for iOS and Android platforms. Our team leverages the latest technologies and frameworks to build apps that are not only visually appealing but also highly functional and responsive."
  },
  {
    title: "Cross-Platform App Development",
    image: verified,
    detail:"Reach a broader audience with our cross-platform app development services. Using frameworks like React Native and Flutter, we develop apps that provide a consistent user experience across both iOS and Android devices, saving you time and resources."
  },
  {
    title: "Enterprise App Development",
    image: verified,
    detail:"Transform your business processes with our enterprise app development solutions. We design and develop robust, secure, and scalable enterprise applications that streamline operations, enhance productivity, and drive growth."
  },
  {
    title: "UI-UX Design",
    image: verified,
    detail:"User experience is at the heart of our app development process. Our skilled designers create intuitive and engaging UI-UX designs that ensure your app is user-friendly and visually compelling, enhancing user satisfaction and retention."
  },
  {
    title: "App Testing and Quality Assurance",
    image: verified,
    detail:"Quality is paramount in our development process. Our rigorous app testing and quality assurance practices ensure your app performs flawlessly across various devices and operating systems. We identify and fix bugs, optimize performance, and ensure a seamless user experience."
  },
  {
    title: "App Maintenance and Support",
    image: verified,
    detail:"Our commitment to your success doesn’t end with the launch. We offer ongoing app maintenance and support services to keep your app up-to-date, secure, and running smoothly. Our team is always ready to address any issues and implement enhancements as needed."
  },
  {
    title: "App Store Optimization (ASO)",
    image: verified,
    detail:"Increase your app’s visibility and downloads with our App Store Optimization services. We optimize your app’s title, description, keywords, and other elements to improve its ranking in app store search results, driving organic growth."
  },
];
const benefits = [
  {
    title: "Experienced Team",
    description:
      "Our team of skilled developers, designers, and strategists has extensive experience in delivering successful app projects across various industries.",
  },
  {
    title: "Customized Solutions",
    description:
      "We understand that every business is unique. Our solutions are tailored to meet your specific needs and objectives.",
  },
  {
    title: "Agile Methodology",
    description:
      "We use agile development practices to ensure flexibility, transparency, and timely delivery of high-quality apps.",
  },
  {
    title: "User-Centric Approach",
    description:
      "Our focus on UI-UX design ensures your app provides exceptional user experience, driving engagement and retention.",
  },
  {
    title: "End-to-End Services",
    description:
      "From concept to deployment and beyond, we provide comprehensive app development services that cover every stage of the development lifecycle.",
  },
  {
    title: "Continuous Support",
    description:
      "We offer continuous maintenance and support to keep your app updated and performing at its best.",
  },
];
function AppDevelopment() {
  return (
    <>
      <WhatsappIcon />
      <Navbar />
      <BreadcrumbHead
        title="App Development"
        subtitile="Transform your app idea into a reality with our experienced development team. Seamless, scalable, and built to succeed."
      />
      <ServiceDescription
        descriptions={descriptions}
        contents={contents}
        mainImage={Laptop}
        heading="Innovative App Development Solutions"
      />
      <Benefits benefits={benefits} />
      <WorkingProcess />
      <Footprint />
      <Footer />
    </>
  );
}

export default AppDevelopment;
