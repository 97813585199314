import React from 'react'
import '../main.css'

// Components Import
import Navbar from '../Components/Navbar/Navbar';
import Footprint from '../Components/Footprint/Footprint';
import Footer from '../Components/Footer/Footer';
import WhatsappIcon from '../Components/WhatsappIcon/WhatsappIcon';
import BlogsContainer from '../Blog/Components/BlogsContainer';

// Sections Import
import Header from './Sections/Header/Header';
import VideoTestimonial from './Sections/VideoTestimonial/VideoTestimonial';
import Strategy from './Sections/Strategy/Strategy';
import ProcessCycle from './Sections/ProcessCycle/ProcessCycle';
// import Services from './Sections/Services/Services';
import Portfolio from './Sections/Portfolio/Portfolio';
import Expertise from './Sections/Expertise/Expertise';
import CaseStudy from './Sections/CaseStudy/CaseStudy';
import Pricings from './Sections/Pricings/Pricings';
import Contact from '../Components/Contact/Contact';
import ServicesListing from '../Services/Components/ServicesListing';


function LandingPage() {

    return (
        <>
            <WhatsappIcon />
            <Navbar />
            <Header />
            <VideoTestimonial />
            <Strategy />
            <ProcessCycle />
            <ServicesListing />
            <Portfolio />
            <Expertise />
            <CaseStudy />
            <Pricings />
            <Contact />
            <BlogsContainer />
            <Footprint />
            <Footer />
        </>
    )
}

export default LandingPage