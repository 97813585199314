import React, { useState } from "react";
import "./CaseStudyPage.css";

function CaseStudyCardDetail({ data,onClick }) {
  return (
    <div
      className={`col-lg-5 col-md-5 col-sm-12 col-12 casestudy-card`}
      onClick={onClick}
    >
      <div className="casestudy-card-content">
        <div className="header-image">
          <img src={data.Image} alt="Service Banner" />
        </div>
        <h3>{data.title}</h3>
        <p>{data.detail}</p>
      </div>
    </div>
  );
}

export default CaseStudyCardDetail;
