import React from "react";
import BreadcrumbHead from "../../../../Components/BreadcrumbHead/BreadcrumbHead";
import Footer from "../../../../Components/Footer/Footer";
import Navbar from "../../../../Components/Navbar/Navbar";
import Introduction from "../Components/Introduction";
import { useLocation } from "react-router-dom";

const descriptions = [
  " A dedicated platform for pet care that provides a vast selection of products and services aimed at enhancing the well-being and happiness of pets. 3nDey PetOasis offers everything from health and wellness essentials to engaging toys and grooming supplies, ensuring pet owners have access to high-quality care solutions. The platform is designed to support pet health and enrichment through a user-friendly experience and comprehensive product range.",
];
function PetOasisProjectDetail() {
  const location = useLocation();
  const { state: project } = location;
  return (
    <>
      <Navbar />
      <BreadcrumbHead title={"PetOasis"} />
      <Introduction
        src={project.imagePath}
        linkUrl={project.linkUrl}
        appStoreUrl={project.appStoreUrl}
        googlePlayUrl={project.googlePlayUrl}
        descriptions={descriptions}
      />
      <Footer />
    </>
  );
}

export default PetOasisProjectDetail;
