import React, { useState } from "react";
import "./Privacy.css";
import { useNavigate } from "react-router-dom";

function Contact() {
    const navigate  = useNavigate()
  return (
    <>
      <div className="container-fluid col-lg-12 col-md-12 col-sm-12 col-12 contact-wrapper">
        <div
          className="start-contact"
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems:'center',
            flexDirection: "column",
          }}
        >
          {/* <img className="star" src={star} alt="Star" /> */}
          <p>Contact Us</p>
          <h3 className="contact-head">
          If you have any questions or concerns about this Privacy Policy, please contact us at <span>hr@9exgen.com</span>
          </h3>
        </div>
      </div>
    </>
  );
}

export default Contact;
