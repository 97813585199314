import React from 'react'
import './ProcessCycle.css'


import star from '../../../../assets/Star.webp';
import GIF from '../../../../assets/ProcessCycle.gif';

function ProcessCycle() {
  return (
    <>
       <div className="container process-cycle-wrapper">
            <div className="row">
                <div className="col-12 process-text">
                    <div className="star-wrapper" style={{ justifyContent: "center", paddingTop: "50px" }}>
                        <img className="star" src={star} alt="Star" />
                        <p>Process Cycle</p>
                    </div>
                    <h3 className='section-heading'>Our Process Cycle</h3>
                </div>
                <div className="gif-wrapper">
                    <img src={GIF} alt="GIF" className="img-fluid gif-image" />
                </div>
            </div>
        </div>
    </>
  )
}

export default ProcessCycle